import { Button, styled } from "@mui/material";
import BG2 from "../assets/BG2.png";

// const useNavigateOnClick = () => {
//   const navigate = useNavigate();
//   return () => {
//     navigate("/festival-details");
//   };
// };

export const Buybutton = styled(Button)({
  boxShadow: "none",
  fontSize: "20px",
  lineHeight: "36px",
  backgroundColor: "#ffffff",
  fontfamily: "Poppins",
  textTransform: "none",
  fontWeight: 600,
  color: "#262625",
  width: "144px",
  padding: "3px 10px",
  // height: "52px",
  borderRadius: "100px",
  "&:hover": {
    fontSize: "20.2px",
    backgroundColor: "lightblue"
  }
});

// export const Morebutton = styled(Button)({
//   boxShadow: "none",
//   fontSize: "20px",
//   lineHeight: "36px",
//   backgroundColor: "transparent",
//   fontfamily: "Poppins",
//   textTransform: "none",
//   border: "2px solid #ffffff",
//   fontWeight: 600,
//   color: "white",
//   width: "144px",
//   padding: "3px 10px",
//   // height: "52px",
//   borderRadius: "100px",
//   "&:hover": {
//     fontSize: "20.2px",
//     backgroundColor: "lightblue"
//   },
//   onClick: () => {
//     useNavigateOnClick();
//   }
// });

export const Morebutton = ({ children, id }) => {
  return (
    <div
      className="relative overflow-hidden z-50 inline-block md:px-4 md:py-2 px-2 py-1 rounded-full text-white uppercase md:text-xs text-[8px] cursor-pointer transition-all duration-300 bg-[#ec4899] group border-2 border-white"
      onClick={() => {
        const tab = window.open(
          `/festival-details/${id.replace(/ /g, "_")}`,
          "_blank",
          "noopener, noreferrer"
        );
        if (tab !== null) {
          tab.blur();
        }
      }}
    >
      <p className="z-50 relative font-Poppins font-medium">{children}</p>
      <span className="absolute bottom-0 left-0 w-full h-0 group-hover:h-full bg-[#1474f4] rounded-full z-0 transition-all duration-300"></span>
    </div>
  );
};

export const h2_style = {
  color: "white",
  fontFamily: "Bowlby One SC",
  fontweight: 400,
  fontSize: "32px",
  lineHeight: "52px"
};

export const bg2 = {
  // background: `url(${BG2}) lightgray 0px -140.106px / 100% 269.003% no-repeat`,
  background: "rgba(255, 255, 255, 0.50)",
  width: "90%",
  borderRadius: "20px",
  zIndex: "15",
  position: "relative"
};

export const bg3 = {
  background: `url(${BG2}) lightgray 0px -2449.165px / 100% 269.003% no-repeat`,
  width: "90%",
  borderRadius: "20px",
  zIndex: "15",
  position: "relative"
};

export const bg4 = {
  background: "rgba(255,255,255,0.4)",
  width: "90%",
  borderRadius: "20px",
  zIndex: "15",
  position: "relative"
};

export const div1 = {
  fontFamily: "Poppins",
  color: "white",
  fontStyle: "normal",
  fontWeight: 700,
  padding: "0px",
  margin: "0px"
};

export const chip = {
  textAlign: "center",
  borderRadius: "8px",
  backgroundColor: "white",
  padding: "0px 10px",
  height: "31px",
  fontFamily: "Poppins",
  fontWeight: 700,
  lineHeight: "36px",
  display: "flex",
  alignItems: "center"
};
