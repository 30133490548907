/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import HomeLayout from "../../layouts/HomeLayout";
import { h2_style } from "../../styles/home";
// import BG2 from "../../assets/BG2.png";
import { GetArtistDetails, GetArtistFestivals } from "../../Client/request";
import { useLocation } from "react-router-dom";
import FestivalCard from "../Home/FestivalCard";
import avatar from "../../assets/avatar.png";

// const bg3 = {
//   background: `url(${BG2}) lightgray 0px -2449.165px / 100% 269.003% no-repeat`
// };

export default function ArtistDetails() {
  const [artist, setArtist] = useState({});
  const [comingFestivals, setComingFestivals] = useState([]);
  const [pastFestivals, setPastFestivals] = useState([]);
  const location = useLocation();

  const getArtistData = async (id) => {
    await GetArtistDetails(id).then(async (res) => {
      setArtist(res);
      await GetArtistFestivals(id).then((res) => {
        // upcoming festivals
        const upcoming = res.filter((f) => new Date(f.FromDate) > new Date());
        setComingFestivals(upcoming);
        // past festivals
        const past = res.filter((f) => new Date(f.FromDate) < new Date());
        setPastFestivals(past);
      });
    });
  };

  useEffect(() => {
    const id = location.pathname.split("/")[2];
    getArtistData(id);
  }, []);

  return (
    <HomeLayout>
      <div className="flex items-center mt-[110px] flex-col md:px-[4rem] px-4 w-full justify-start">
        <h1 style={{ ...h2_style, width: "100%", zIndex: "15" }}>
          {artist.Name}
        </h1>
        <div className="flex lg:flex-row flex-col md:items-start items-center justify-start w-full bg-white bg-opacity-50 p-4 md:p-8 rounded-xl">
          <div>
            {artist.ArtistImage ? (
              <img
                src={artist.ArtistImage}
                alt="avatar"
                className="h-[200px] w-[150px] object-cover object-center"
              />
            ) : (
              <img
                src={avatar}
                alt="avatar"
                className="h-[200px] w-[150px] object-cover object-center"
              />
            )}
          </div>
          <div className="lg:ml-[3rem] m-0">
            <div className="flex justify-start items-start text-white my-[1.5rem]">
              <p className="w-[5rem] sm:w-[14rem] text-sm md:text-[28px] font-medium m-0">
                Name
              </p>
              <p className="text-sm md:text-[22px] m-0">{artist.Name}</p>
            </div>
            <div className="flex justify-start items-start text-white mb-[1.5rem]">
              <p className="w-[5rem] sm:w-[14rem] text-sm md:text-[28px] font-medium m-0">
                Date of birth
              </p>
              <p className="text-sm md:text-[22px] m-0">
                {new Date(artist.Dob).toLocaleDateString("en-US", {
                  weekday: "long",
                  year: "numeric",
                  month: "long",
                  day: "numeric"
                })}
              </p>
            </div>
            <div className="flex justify-start items-start text-white mb-[1.5rem]">
              <p className="w-[5rem] sm:w-[14rem] text-sm md:text-[28px] font-medium m-0">
                Birthplace
              </p>
              <p className="text-sm md:text-[22px] m-0">{artist.Birthplace}</p>
            </div>
            <div className="flex justify-start items-start text-white mb-[1.5rem]">
              <p className="w-[5rem] sm:w-[14rem] text-sm md:text-[28px] font-medium m-0">
                Genres
              </p>
              <p className="text-sm md:text-[22px] m-0">
                {artist.Genres ? artist.Genres.join(",") : ""}
              </p>
            </div>
            <div className="flex items-start justify-start text-white md:mb-6">
              <p className="w-[5rem] sm:w-[14rem] text-sm md:text-[28px] font-medium m-0">
                Website
              </p>
              <p
                className="text-sm md:text-[22px] m-0 text-blue-500 cursor-pointer whitespace-normal break-all"
                onClick={() => {
                  window.open(
                    artist.Website?.includes("https://")
                      ? artist.Website
                      : `https://${artist.Website}`,
                    "_blank"
                  );
                }}
              >
                {artist.Website?.includes("https://")
                  ? artist.Website
                  : `https://${artist.Website}`}
              </p>
            </div>
            {(artist.Facebook ||
              artist.Instagram ||
              artist.Soundcloud ||
              artist.Spotify) && (
              <div className="bg-[#1474F4] p-[10px] flex flex-row justify-evenly items-center rounded-[10px] w-fit gap-4 mt-16 flex-wrap">
                {artist.Facebook && (
                  <img
                    className="cursor-pointer md:h-6 h-4 object-cover"
                    src="/social-icons/fb.svg"
                    alt="social icons"
                    onClick={() =>
                      window.open(
                        `https://www.facebook.com/${artist.Facebook}`,
                        "_blank"
                      )
                    }
                  />
                )}
                {artist.Instagram && (
                  <img
                    className="cursor-pointer md:h-6 h-4 object-cover"
                    src="/social-icons/insta.svg"
                    alt="social icons"
                    onClick={() =>
                      window.open(
                        `http://instagram.com/_u/${artist.Instagram}`,
                        "_blank"
                      )
                    }
                  />
                )}
                {artist.Youtube && (
                  <img
                    className="cursor-pointer md:h-6 h-4 object-cover"
                    src="/social-icons/youtube.svg"
                    alt="social icons"
                    onClick={() =>
                      window.open(
                        `https://www.youtube.com/user/${artist.Youtube}`,
                        "_blank"
                      )
                    }
                  />
                )}
                {artist.Soundcloud && (
                  <img
                    className="cursor-pointer md:h-6 h-4 object-cover"
                    src="/social-icons/soundcloud.svg"
                    alt="social icons"
                    onClick={() =>
                      window.open(
                        `https://soundcloud.com/${artist.Soundcloud}`,
                        "_blank"
                      )
                    }
                  />
                )}
                {artist.Spotify && (
                  <img
                    className="cursor-pointer md:h-6 h-4 object-cover"
                    src="/social-icons/spotify.svg"
                    alt="social icons"
                    onClick={() =>
                      window.open(
                        `https://open.spotify.com/artist/${artist.Spotify}`,
                        "_blank"
                      )
                    }
                  />
                )}
              </div>
            )}
          </div>
        </div>
        <p className="w-full z-10 text-white text-sm md:text-[22px] mt-6 bg-white bg-opacity-50 p-4 rounded-xl">
          {artist.Description}
        </p>
      </div>
      {Array.isArray(comingFestivals) && comingFestivals.length !== 0 && (
        <>
          <div className="flex justify-center mt-[50px] md:mx-[4rem] mx-4 bg-white bg-opacity-50 rounded-xl">
            <h1 className="text-white font-Bowlby md:text-[32px] md:leading-[52px] text-2xl leading-10 p-4 text-left">
              Aanwezig op de volgende festivals
            </h1>
          </div>
          <div className="flex flex-col items-center justify-center mt-5 gap-2 md:px-[4rem] px-4 w-full">
            {Array.isArray(comingFestivals) &&
              comingFestivals.map((f, index) => (
                <div key={index} className="w-full">
                  <FestivalCard f={f} />
                </div>
              ))}
          </div>
        </>
      )}
      {Array.isArray(pastFestivals) && pastFestivals.length !== 0 && (
        <>
          <div className="flex justify-center mt-[50px] md:mx-[4rem] mx-4 bg-white bg-opacity-50 rounded-xl">
            <h1 className="text-white font-Bowlby md:text-[32px] md:leading-[52px] text-2xl leading-10 p-4 text-left">
              Was aanwezig op de volgende festivals
            </h1>
          </div>
          <div className="flex flex-col items-center justify-center mt-5 gap-2 md:px-[4rem] px-4 w-full">
            {Array.isArray(pastFestivals) &&
              pastFestivals.map((f, index) => (
                <div key={index} className="w-full">
                  <FestivalCard f={f} />
                </div>
              ))}
          </div>
        </>
      )}

      {/* <FestivalsCalender varient="details" /> */}
      {/* <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: "50px"
        }}
      >
        <div style={{ ...bg2 }}>
          <h1 style={{ ...h2_style, textAlign: "left", paddingLeft: "2rem" }}>
            Benny Rodrigues will soon be playing at these locations
          </h1>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: "50px"
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "20rem",
            background: "rgba(255, 255, 255, 0.30)",
            width: "90%",
            borderRadius: "20px",
            zIndex: "15",
            position: "relative"
          }}
        >
          <h1 style={{ ...h2_style }}>MAP</h1>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: "50px"
        }}
      >
        <div style={{ ...bg2 }}>
          <h1 style={{ ...h2_style, textAlign: "left", paddingLeft: "2rem" }}>
            Related artists
          </h1>
        </div>
      </div>
      <div
        style={{
          marginTop: "50px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          overflow: "hidden"
        }}
      >
        <div
          style={{
            ...bg4,
            padding: "50px",
            width: "85%",
            maxHeight: "50rem",
            overflowY: "scroll",
            height: "100%"
          }}
        >
          <div
            style={{
              display: "flex",
              flexWrap: "wrap"
            }}
          >
            {[...Array(100)].map((_) => (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  marginRight: "2rem",
                  marginBottom: "2rem"
                }}
              >
                <div
                  style={{
                    width: 100,
                    height: 100,
                    borderRadius: 100,
                    opacity: 0.5,
                    background: "#FFF"
                  }}
                />
                <h5 style={{ marginLeft: "1rem" }}>Banny Rodigues</h5>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: "50px"
        }}
      >
        <div style={{ ...bg2 }}>
          <h1 style={{ ...h2_style, textAlign: "left", paddingLeft: "2rem" }}>
            Present at the following festivals:
          </h1>
        </div>
      </div>
      <FestivalsCalender varient="details" /> */}
    </HomeLayout>
  );
}
