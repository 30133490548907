// import logo from './logo.svg';
import "./App.css";
import ArtistDetails from "./Pages/ArtistDetails/ArtistDetails";
// import ArtistsOverview from "./Pages/ArtistsOverview/ArtistsOverview";
import FestivalDetails from "./Pages/Festival Detail/FestivalDetails";
import Contact from "./Pages/Contact/Contact";
import Home from "./Pages/Home/Home";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import AddEvent from "./Pages/AddEvent/AddEvent";
import WinTickets from "./Pages/WinTickets/WinTickets";
import PromoteEvent from "./Pages/PromoteEvent/PromoteEvent";

function App() {
  return (
    <>
      <Router>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route path="/add_event" element={<AddEvent />} />
          <Route path="/promote_event" element={<PromoteEvent />} />
          {/* <Route path="/over_ons" element={<Home />} />
          <Route path="/festival_reports" element={<Home />} /> */}
          <Route path="/giveaways" element={<WinTickets />} />
          <Route path="/contact" element={<Contact />} />
          {/* <Route path="/artists-overview" element={<ArtistsOverview />} /> */}
          <Route path="/artists-details/:id" element={<ArtistDetails />} />
          <Route path="/festival-details/:id" element={<FestivalDetails />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
