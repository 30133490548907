import React, { useEffect, useState } from "react";
// import { useNavigate } from "react-router-dom";
import HomeLayout from "../../layouts/HomeLayout";
// import HeroInfoSection from "../../Components/HeroInfoSection/HeroInfoSection";
import {
  AddEvent as AddFestival,
  GetArtists,
  AddFestivalArtists
} from "../../Client/request";

import { Formik, Field, useField, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import DatePicker from "react-datepicker";
import BG2 from "../../assets/BG2.png";

import "react-datepicker/dist/react-datepicker.css";
import { IoCloseCircleOutline } from "react-icons/io5";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const AddEventSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Name is Required"),
  phone_number: Yup.string().required("Contact is Required"),
  email: Yup.string().email("Invalid email").required("Email is Required"),
  land: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Location is Required"),
  locatie: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Place is Required"),
  fromdate: Yup.date().required("Date is Required"),
  enddate: Yup.date().required("End Date is Required"),
  fromtime: Yup.string().required("Time is Required"),
  endtime: Yup.string().required("End Time is Required"),
  price: Yup.string().required("Price is Required"),
  tickets_url: Yup.string().url("Invalid URL"),

  instagram_url: Yup.string().url("Invalid URL"),

  festival_description: Yup.string(),
  additional_info: Yup.string()
});

const MyDatePicker = ({ name = "", placeholder }) => {
  const [field, meta, helpers] = useField(name);

  const { value } = meta;
  const { setValue } = helpers;

  return (
    <DatePicker
      {...field}
      placeholderText={placeholder}
      selected={value}
      onChange={(date) => {
        setValue(date);
      }}
      dateFormat="dd/MM/yyyy"
      className="cursor-pointer focus:outline-0 w-full h-[50px] rounded-lg p-4 bg-transparent border-2 border-white placeholder:text-white text-white font-Poppins text-xl"
      wrapperClassName="w-full"
      lang="nl"
    />
  );
};

const MyTimePicker = ({ name = "", placeholder }) => {
  const [field, meta, helpers] = useField(name);

  const { value } = meta;
  const { setValue } = helpers;

  return (
    <DatePicker
      {...field}
      placeholderText={placeholder}
      selected={value}
      onChange={(date) => {
        setValue(date);
      }}
      showTimeSelect
      showTimeSelectOnly
      timeIntervals={15}
      timeCaption="Time"
      dateFormat="Pp"
      className="cursor-pointer focus:outline-0 w-full h-[50px] rounded-lg p-4 bg-transparent border-2 border-white placeholder:text-white text-white font-Poppins text-xl"
      wrapperClassName="w-full"
    />
  );
};

const bg2 = {
  background: `url(${BG2}) #B3DDFF 0px -140.106px / 100% 269.003% no-repeat`
};

const AddEvent = () => {
  // const [festivalId, setFestivalId] = useState();
  const [modal, setModal] = useState(false);
  const [artists, setArtists] = useState([]);
  const [selectedArtists, setSelectedArtists] = useState([]);
  const [loader, setLoader] = useState(false);
  const [promotion, setPromotion] = useState(false);
  const [pageLoading, setPageLoading] = useState(true);

  // const navigate = useNavigate();

  const getAllArtists = async () => {
    await GetArtists().then((res) => {
      setArtists(res.data);
    });
  };

  useEffect(() => {
    // after 2 seconds set pageLoading to false
    setTimeout(() => {
      setPageLoading(false);
    }, 400);
  }, []);

  const addFestivalArtists = async () => {
    const data = {
      artists_ids: selectedArtists.map((artist) => artist.ArtistID)
      // festival_Id: festivalId
    };

    await AddFestivalArtists(data).then((res) => {
      console.log(res);
    });
    setModal(false);
  };

  useEffect(() => {
    getAllArtists();
  }, []);

  return (
    <>
      {pageLoading ? (
        <>
          {/* loader */}
          <div className="flex items-center justify-center h-screen w-screen bg-[#5ACCFF]">
            <div className="flex items-center justify-center flex-col">
              <h1 className="text-white font-Bowlby text-2xl md:text-5xl">
                Event toevoegen
              </h1>
              <div className="flex items-center justify-center">
                <div className="animate-spin rounded-full h-20 md:h-32 w-20 md:w-32 border-t-2 border-[#fff]"></div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <HomeLayout>
          {modal && (
            <div
              className={`fixed z-50 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white rounded-lg  shadow-2xl ${
                modal ? " w-[300px]  md:w-[500px]" : "w-0"
              } transition-all duration-300 ease-in-out overflow-hidden`}
            >
              <div className=" p-4 rounded-lg flex flex-col items-center justify-center w-full gap-4">
                <h1 className="text-xl font-bold font-Bowlby w-full text-left">
                  Add Artist Line Up
                </h1>
                <div className="w-full rounded-2xl bg-gray-300 h-[150px] px-4 py-2 flex flex-row items-start justify-start flex-wrap gap-2">
                  {selectedArtists.length === 0 && (
                    <p className="font-Poppins text-base w-full text-left font-bold text-white">
                      No Artists Selected
                    </p>
                  )}
                  {selectedArtists.map((artist, index) => (
                    <div
                      className="flex flex-row items-center justify-center px-2 py-1 bg-white rounded-full gap-1"
                      key={index}
                    >
                      <p className="font-Poppins text-base w-full text-left font-bold ml-1">
                        {artist.Name}
                      </p>
                      <IoCloseCircleOutline
                        className="text-xl cursor-pointer"
                        onClick={() => {
                          setSelectedArtists(
                            selectedArtists.filter((a) => a !== artist)
                          );
                        }}
                      />
                    </div>
                  ))}
                </div>
                <div className="w-full flex flex-col items-start justify-center gap-2">
                  <p className="font-Poppins text-base w-full text-left font-bold">
                    Select Artists
                  </p>
                  <select
                    className="focus:outline-0 w-full h-[50px] rounded-lg px-2 bg-transparent border-2 border-black placeholder:text-black text-black font-Poppins text-xl cursor-pointer"
                    id="artists"
                    name="artists"
                    onChange={(e) => {
                      const selectedArtist = artists.find(
                        (artist) => artist.Name === e.target.value
                      );
                      setSelectedArtists([...selectedArtists, selectedArtist]);
                    }}
                  >
                    <option
                      value=""
                      className="text-black w-full cursor-pointer"
                    >
                      Select an Artist
                    </option>
                    {artists.length > 0 &&
                      artists.map((artist) => (
                        <option
                          key={artist.ArtistID}
                          value={artist.Name}
                          className="text-black w-full cursor-pointer"
                        >
                          {artist.Name}
                        </option>
                      ))}
                  </select>
                </div>
                <div className="relative overflow-hidden z-50 px-4 py-2 rounded-md text-white uppercase text-base font-bold cursor-pointer transition-all duration-300 bg-[#1474f4] group">
                  <button
                    type="submit"
                    className="z-50 relative"
                    disabled={selectedArtists.length === 0}
                    onClick={addFestivalArtists}
                  >
                    Add Artists
                  </button>
                  <span className="absolute bottom-0 left-0 w-full h-0 group-hover:h-full bg-[#ec4899] rounded-md z-0 transition-all duration-300"></span>
                </div>
              </div>
            </div>
          )}
          <div
            className={`flex items-center justify-center flex-col gap-4 md:w-full md:mt-40 mt-28 z-20 ${
              modal ? "blur-2xl" : ""
            }`}
          >
            <h1 className="text-5xl md:text-[90px] font-Bowlby leading-[52px] md:leading-[99px] text-center text-white relative z-10">
              Event toevoegen
            </h1>

            <div className="flex justify-center flex-col items-center md:w-full w-[90%]">
              <div
                style={{ ...bg2 }}
                className="md:w-[80%] w-full p-4 rounded-[20px] z-10 relative"
              >
                <p className="md:block hidden font-Poppins text-xl text-white w-full z-20 font-medium text-center md:px-0 px-2">
                  Mist er een festival? Of wil jij je eigen event toevoegen? Vul
                  het onderstaand formulier in.
                </p>
                <p className="block md:hidden font-Poppins text-xl text-white w-full z-20 font-medium text-center md:px-0 px-2 mb-4">
                  Ben jij als organisator op zoek naar een nieuwe manier om je
                  evenement onder de aandacht te brengen? beFesti heeft een
                  uniek bereik met het grootste festival platform op Social
                  Media (Instagram & TikTok).
                </p>
                <p className="block md:hidden font-Poppins text-xl text-white w-full z-20 font-medium text-center md:px-0 px-2">
                  Bovendien heeft beFesti een enorm bereik op deze website.
                  Doordat beFesti deze mogelijkheden met elkaar combineerd
                  helpen wij festivals & events met het boosten van hun
                  ticketsale en naamsbekendheid.
                </p>
              </div>
            </div>
            <Formik
              initialValues={{
                name: "",
                email: "",
                phone_number: "",
                land: "",
                locatie: "",
                fromdate: "",
                enddate: "",
                fromtime: "",
                endtime: "",
                price: "",
                tickets_url: "",
                instagram_url: "",
                festival_description: "",
                additional_info: ""
              }}
              validationSchema={AddEventSchema}
              onSubmit={async (values) => {
                setLoader(true);
                // get month as Aug from from date
                values.month = values.fromdate.toString().split(" ")[1];
                values.metadescription = `<p>This is tickets URL</p><a>${values.tickets_url}</a><p>This is Instagram URL</p><a>${values.instagram_url}</a><p>This is additional info</p><p>${values.additional_info}</p>`;
                values.from_date = new Date(
                  values.fromdate.toString()
                ).toISOString();
                values.end_date = new Date(
                  values.enddate.toString()
                ).toISOString();
                values.from_time = new Date(
                  values.fromtime.toString()
                ).toLocaleTimeString([], {
                  hour: "2-digit",
                  minute: "2-digit",
                  hour12: true
                });
                values.end_time = new Date(
                  values.endtime.toString()
                ).toLocaleTimeString([], {
                  hour: "2-digit",
                  minute: "2-digit",
                  hour12: true
                });
                values.is_promoted = promotion;
                await AddFestival(values).then((res) => {
                  setLoader(false);
                  if (!res.error) {
                    toast.success("Event Added Successfully", {
                      position: "bottom-right",
                      autoClose: 5000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined
                    });
                  } else {
                    toast.error(res.error, {
                      position: "bottom-right",
                      autoClose: 5000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined
                    });
                  }
                  // set form values to initial values
                });
              }}
            >
              <Form className="flex flex-col items-center justify-center w-full">
                <div className="flex flex-col items-start justify-center md:max-w-[70%] w-full z-20 gap-4 mt-10 md:px-0 px-2">
                  <div className="flex md:flex-row flex-col items-start justify-between w-full gap-2">
                    <div className="flex flex-col items-start justify-center w-full">
                      <Field
                        id="name"
                        name="name"
                        placeholder="Naam Festival *"
                        className="focus:outline-0 w-[100%] h-[50px] rounded-lg p-4 bg-transparent border-2 border-white placeholder:text-white text-white font-Poppins text-xl"
                      />
                      <ErrorMessage
                        name="name"
                        className="ml-1 text-red-500 font-sm font-Poppins"
                        component="p"
                      />
                    </div>
                    <div className="flex flex-col items-start justify-center w-full">
                      <Field
                        id="phone_number"
                        name="phone_number"
                        placeholder="Contact *"
                        className="focus:outline-0 w-[100%] h-[50px] rounded-lg p-4 bg-transparent border-2 border-white placeholder:text-white text-white font-Poppins text-xl"
                      />
                      <ErrorMessage
                        name="phone_number"
                        className="ml-1 text-red-500 font-sm font-Poppins"
                        component="p"
                      />
                    </div>
                  </div>
                  <div className="flex md:flex-row flex-col items-start justify-between w-full gap-2">
                    <div className="flex flex-col items-start justify-center w-full">
                      <Field
                        id="email"
                        name="email"
                        placeholder="E-mailadres *"
                        className="focus:outline-0 w-[100%] h-[50px] rounded-lg p-4 bg-transparent border-2 border-white placeholder:text-white text-white font-Poppins text-xl"
                      />
                      <ErrorMessage
                        name="email"
                        className="ml-1 text-red-500 font-sm font-Poppins"
                        component="p"
                      />
                    </div>
                    <div className="flex flex-col items-start justify-center w-full">
                      <Field
                        id="price"
                        name="price"
                        placeholder="Prijs *"
                        className="focus:outline-0 w-[100%] h-[50px] rounded-lg p-4 bg-transparent border-2 border-white placeholder:text-white text-white font-Poppins text-xl"
                      />
                      <ErrorMessage
                        name="price"
                        className="ml-1 text-red-500 font-sm font-Poppins"
                        component="p"
                      />
                    </div>
                  </div>
                  <div className="flex md:flex-row flex-col items-start justify-between w-full gap-2">
                    <div className="flex flex-col items-start justify-center w-full">
                      <Field
                        id="land"
                        name="land"
                        placeholder="Plaats *"
                        className="focus:outline-0 w-[100%] h-[50px] rounded-lg p-4 bg-transparent border-2 border-white placeholder:text-white text-white font-Poppins text-xl"
                      />
                      <ErrorMessage
                        name="land"
                        className="ml-1 text-red-500 font-sm font-Poppins"
                        component="p"
                      />
                    </div>
                    <div className="flex flex-col items-start justify-center w-full">
                      <Field
                        id="locatie"
                        name="locatie"
                        placeholder="Locatie *"
                        className="focus:outline-0 w-[100%] h-[50px] rounded-lg p-4 bg-transparent border-2 border-white placeholder:text-white text-white font-Poppins text-xl"
                      />
                      <ErrorMessage
                        name="locatie"
                        className="ml-1 text-red-500 font-sm font-Poppins"
                        component="p"
                      />
                    </div>
                  </div>
                  <div className="flex md:flex-row flex-col items-start justify-between w-full gap-2">
                    <div className="flex flex-col items-start justify-center w-full">
                      <MyDatePicker name="fromdate" placeholder="Datum *" />
                      <ErrorMessage
                        name="fromdate"
                        className="ml-1 text-red-500 font-sm font-Poppins"
                        component="p"
                      />
                    </div>
                    <div className="flex flex-col items-start justify-center w-full">
                      <MyDatePicker name="enddate" placeholder="Einddatum *" />
                      <ErrorMessage
                        name="enddate"
                        className="ml-1 text-red-500 font-sm font-Poppins"
                        component="p"
                      />
                    </div>
                  </div>
                  <div className="flex md:flex-row flex-col items-start justify-between w-full gap-2">
                    <div className="flex flex-col items-start justify-center w-full">
                      <MyTimePicker
                        name="fromtime"
                        placeholder="Tijd vanaf *"
                      />
                      <ErrorMessage
                        name="fromtime"
                        className="ml-1 text-red-500 font-sm font-Poppins"
                        component="p"
                      />
                    </div>
                    <div className="flex flex-col items-start justify-center w-full">
                      <MyTimePicker name="endtime" placeholder="Tijd tot *" />
                      <ErrorMessage
                        name="endtime"
                        className="ml-1 text-red-500 font-sm font-Poppins"
                        component="p"
                      />
                    </div>
                  </div>

                  <div className="flex flex-col items-center justify-between w-full gap-2">
                    <div className="flex flex-col items-start justify-center w-full">
                      <Field
                        id="festival_description"
                        name="festival_description"
                        as="textarea"
                        placeholder="Beschrijving"
                        rows={4}
                        className="focus:outline-0 w-[100%] rounded-lg p-4 bg-transparent border-2 border-white placeholder:text-white text-white font-Poppins text-xl resize-none "
                      />
                      <ErrorMessage
                        name="festival_description"
                        className="ml-1 text-red-500 font-sm font-Poppins"
                        component="p"
                      />
                    </div>
                    {/* <div className="flex flex-col items-start justify-center w-full">
                <p className="font-Poppins text-xl text-white font-medium ml-2">
                  Line Up
                </p>
                <textarea
                  type="text"
                  placeholder="Line Up"
                  rows={4}
                  className="focus:outline-0 w-[100%] rounded-lg p-4 bg-transparent border-2 border-white placeholder:text-white text-white font-Poppins text-xl resize-none "
                />
              </div> */}
                  </div>
                  <div className="flex md:flex-row flex-col items-start justify-between w-full gap-2">
                    <div className="flex flex-col items-start justify-center w-full">
                      <Field
                        id="tickets_url"
                        name="tickets_url"
                        placeholder="Ticket-URL"
                        className="focus:outline-0 w-[100%] h-[50px] rounded-lg p-4 bg-transparent border-2 border-white placeholder:text-white text-white font-Poppins text-xl"
                      />
                      <ErrorMessage
                        name="tickets_url"
                        className="ml-1 text-red-500 font-sm font-Poppins"
                        component="p"
                      />
                    </div>
                    <div className="flex flex-col items-start justify-center w-full">
                      <Field
                        id="instagram_url"
                        name="instagram_url"
                        placeholder="Instagram-URL"
                        className="focus:outline-0 w-[100%] h-[50px] rounded-lg p-4 bg-transparent border-2 border-white placeholder:text-white text-white font-Poppins text-xl"
                      />
                      <ErrorMessage
                        name="instagram_url"
                        className="ml-1 text-red-500 font-sm font-Poppins"
                        component="p"
                      />
                    </div>
                  </div>

                  <Field
                    id="additional_info"
                    name="additional_info"
                    as="textarea"
                    placeholder="Extra informatie"
                    rows={4}
                    className="focus:outline-0 w-[100%] rounded-lg p-4 bg-transparent border-2 border-white placeholder:text-white text-white font-Poppins text-xl resize-none "
                  />
                  <ErrorMessage
                    name="additional_info"
                    className="ml-1 text-red-500 font-sm font-Poppins"
                    component="p"
                  />
                  <div className="flex md:flex-row flex-col items-center md:justify-between w-full gap-2">
                    <div className="flex flex-row items-center md:justify-start justify-between md:w-fit w-full md:gap-4">
                      <p className="font-Poppins md:text-lg text-white text-[16px]">
                        Interesse in mogelijke promotie?
                      </p>
                      <input
                        type="checkbox"
                        value={promotion}
                        onChange={(e) => setPromotion(e.target.value)}
                        className="focus:outline-0 md:w-[16px] md:h-[16px] w-[12px] h-[12px] cursor-pointer rounded-lg p-2 bg-transparent border-2 border-white"
                      />
                    </div>
                    {/* <p
                  className="font-Poppins text-xl text-blue-900 font-medium w-full text-left md:text-right cursor-pointer capitalize"
                  onClick={() => navigate("/promote_event")}
                >
                  Meer weten over event promoten?
                </p> */}
                    <p className="font-Poppins text-sm text-white md:w-fit w-full">
                      * is een verplicht veld.
                    </p>
                  </div>
                  {/* * is  required for formik to recognize the button */}
                  <div className="w-full md:inline-block flex justify-center">
                    <div className="relative overflow-hidden z-50 md:inline-block px-4 py-2 rounded-md text-white uppercase text-base font-bold cursor-pointer transition-all duration-300 bg-[#1474f4] group">
                      <button
                        type="submit"
                        className="z-50 relative md:w-[200px] w-fit"
                        disabled={loader}
                      >
                        {loader ? (
                          <div className="flex flex-row items-center justify-center gap-2">
                            <div className="w-4 h-4 border-t-2 border-white rounded-full animate-spin"></div>
                          </div>
                        ) : (
                          "Voeg event toe"
                        )}
                      </button>
                      <span className="absolute bottom-0 left-0 w-full h-0 group-hover:h-full bg-[#ec4899] rounded-md z-0 transition-all duration-300"></span>
                    </div>
                  </div>
                </div>
              </Form>
            </Formik>
            <ToastContainer />
          </div>
        </HomeLayout>
      )}
    </>
  );
};

export default AddEvent;
