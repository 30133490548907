import React, { useEffect } from "react";
import Navbar from "../Components/Navbar";
import Footer from "../Components/Footer";
// import BG from "../assets/BG.png";

export default function HomeLayout({ children }) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="bg-cover bg-left-top bg-no-repeat w-full flex flex-col items-center justify-start h-full bg-[#5ACCFF] min-h-screen">
      <div className="max-w-[2560px] w-full h-full">
        <Navbar />
        <div className="w-full">{children}</div>
        <Footer />
      </div>
    </div>
  );
}
