export const Genre = [
  "Techno",
  "Hardstyle",
  "Hardcore",
  "House",
  "Pop",
  "Urban",
  "Trance"
];

export const genreColors = [
  { genre: "techno", color: "#84bde8" },
  { genre: "house", color: "#F3A3C8" },
  { genre: "hardstyle", color: "#BC9FCB" },
  { genre: "hardcore", color: "#d0e09f" },
  { genre: "urban", color: "#f79191" },
  { genre: "pop", color: "#FFF481" },
  { genre: "trance", color: "#70DBCD" }
];
