import React, { useState, useEffect } from "react";
import HomeLayout from "../../layouts/HomeLayout";
import CountUp from "react-countup";
// import dummy from "../../assets/dummy.jpg";
// import Card from "../../Components/Card/card";
import SmallCard from "../../Components/SmallCard/SmallCard";
import { AddContact } from "../../Client/request";
import { useRef } from "react";

import VisibilitySensor from "react-visibility-sensor";

const PromoteEvent = () => {
  const [Email, setEmail] = useState("");
  const [Name, setName] = useState("");
  const [Message, setMessage] = useState("");
  const [festival, setFestival] = useState("");
  const [Contact, setContact] = useState("");
  const [errors, setErrors] = useState({});
  const [pageLoading, setPageLoading] = useState(true);

  const contactRef = useRef();

  const scrollToContact = () => {
    contactRef.current.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    // after 2 seconds set pageLoading to false
    setTimeout(() => {
      setPageLoading(false);
    }, 400);
  }, []);

  const handleSubmit = async (e) => {
    const newErrors = {};

    if (Name.trim() === "") {
      newErrors.name = "Name is required";
    }

    if (Email.trim() === "") {
      newErrors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(Email)) {
      newErrors.email = "Email is invalid";
    }

    if (festival.trim() === "") {
      newErrors.festival = "Event name is required";
    }

    if (Message.trim() === "") {
      newErrors.message = "Message is required";
    }

    if (Contact.trim() === "") {
      newErrors.contact = "Contact is required";
    }

    setErrors(newErrors);

    if (Object.keys(newErrors).length > 0) {
      return;
    }

    e.preventDefault();
    const data = {
      name: Name,
      email: Email,
      contact: Contact,
      message: `${festival} \n ${Message}`
    };
    const response = await AddContact(data);
    setEmail("");
    setName("");
    setMessage("");
    setFestival("");
    console.log(response);
  };
  return (
    <>
      {pageLoading ? (
        <>
          {/* loader */}
          <div className="flex items-center justify-center h-screen w-screen bg-[#5ACCFF]">
            <div className="flex items-center justify-center flex-col">
              <h1 className="text-white font-Bowlby text-2xl md:text-5xl">
                Event promoten
              </h1>
              <div className="flex items-center justify-center">
                <div className="animate-spin rounded-full h-20 md:h-32 w-20 md:w-32 border-t-2 border-[#fff]"></div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <HomeLayout>
          <div className="flex items-center justify-center md:mt-40 mt-28 flex-col gap-8 w-full z-20">
            <h1 className="text-5xl md:text-[90px] font-Bowlby leading-[52px] md:leading-[99px] text-center text-white relative z-10">
              Event promoten
            </h1>

            <div className="flex flex-col items-center justify-center w-[90%] gap-4 z-10">
              <p className="font-Poppins text-[28px] leading-[32px] font-semibold text-white text-center w-full justify-center items-center">
                Bereik de perfecte doelgroep voor jouw event
              </p>
              <p className="font-Poppins text-[16px] leading-[24px] font-medium text-white text-center w-full justify-center items-center">
                Ben jij als organisator op zoek naar een nieuwe manier om je
                evenement onder de aandacht te brengen? beFesti heeft een uniek
                bereik met het grootste festival platform op Social Media
                (Instagram & TikTok).
              </p>
              <p className="font-Poppins text-[16px] leading-[24px] font-medium text-white text-center w-full justify-center items-center">
                Bovendien heeft beFesti een enorm bereik op deze website.
                Doordat beFesti deze mogelijkheden met elkaar combineerd helpen
                wij festivals & events met het boosten van hun ticketsale en
                naamsbekendheid.
              </p>
              <div className="flex flex-row items-start justify-start gap-8 z-10 flex-wrap">
                <div className="flex flex-col items-start justify-center gap-2 z-50">
                  <div className="flex flex-row items-end justify-center gap-5 z-50">
                    <CountUp start={0} end={40000} separator="." decimal=",">
                      {({ countUpRef, start }) => (
                        <div>
                          <VisibilitySensor onChange={start} partialVisibility>
                            <span
                              ref={countUpRef}
                              className="font-Poppins text-[74px] leading-[80px] font-medium text-white"
                            />
                          </VisibilitySensor>
                        </div>
                      )}
                    </CountUp>
                  </div>
                  <p className="font-Poppins  text-[16px] leading-[24px] text-white">
                    Volgers op Social Media
                  </p>
                  <div className="h-[1px] w-[308px] bg-white" />
                  <div className="flex flex-row items-end justify-center gap-5 z-50">
                    <CountUp start={0} end={1}>
                      {({ countUpRef, start }) => (
                        <div>
                          <VisibilitySensor onChange={start} partialVisibility>
                            <span
                              ref={countUpRef}
                              className="font-Poppins text-[74px] leading-[80px] font-medium text-white"
                            />
                          </VisibilitySensor>
                        </div>
                      )}
                    </CountUp>
                    <p className="font-Poppins text-[74px] leading-[80px] font-medium text-white">
                      miljoen
                    </p>
                  </div>
                  <p className="font-Poppins  text-[16px] leading-[24px] text-white">
                    Maandelijke weergaven op Social Media
                  </p>
                  <div className="h-[1px] w-[308px] bg-white" />
                </div>

                <div className="flex flex-col items-start justify-center gap-2 z-50">
                  <div className="flex flex-row items-end justify-center gap-5 z-50">
                    <CountUp start={0} end={200000} separator="." decimal=",">
                      {({ countUpRef, start }) => (
                        <div>
                          <VisibilitySensor onChange={start} partialVisibility>
                            <span
                              ref={countUpRef}
                              className="font-Poppins text-[74px] leading-[80px] font-medium text-white"
                            />
                          </VisibilitySensor>
                        </div>
                      )}
                    </CountUp>
                  </div>
                  <p className="font-Poppins  text-[16px] leading-[24px] text-white">
                    Maandelijkse weergaven op befesti.nl
                  </p>
                  <div className="h-[1px] w-[308px] bg-white" />
                  <div className="flex flex-row items-end justify-center gap-5 z-50">
                    <CountUp start={0} end={63}>
                      {({ countUpRef, start }) => (
                        <div>
                          <VisibilitySensor onChange={start} partialVisibility>
                            <span
                              ref={countUpRef}
                              className="font-Poppins text-[74px] leading-[80px] font-medium text-white"
                            />
                          </VisibilitySensor>
                        </div>
                      )}
                    </CountUp>
                    <p className="font-Poppins text-[74px] leading-[80px] font-medium text-white">
                      %
                    </p>
                  </div>
                  <p className="font-Poppins  text-[16px] leading-[24px] text-white">
                    Van de festivalgangers is bekend met beFesti
                  </p>
                  <div className="h-[1px] w-[308px] bg-white" />
                </div>
              </div>
              <div className="flex flex-row items-center justify-center w-[90%] gap-5 z-10">
                <div className="relative overflow-hidden z-50 md:inline-block px-4 py-2 rounded-full text-white font-Poppins text-[18px] font-semibold select-none cursor-pointer transition-all duration-300 bg-[#ec4899] group">
                  <p className="z-50 relative" onClick={scrollToContact}>
                    Neem contact op
                  </p>
                  <span className="absolute bottom-0 left-0 w-full h-0 group-hover:h-full bg-[#1474f4] rounded-full z-0 transition-all duration-300"></span>
                </div>
              </div>
            </div>

            {/* <div className="w-full z-10 bg-[#A6DCFF] flex flex-col items-center justify-center py-4 gap-4">
          <h1 className="text-left font-Bowlby text-[32px] leading-[52px] text-white w-[90%]">
            De meest waardevolle klantreis van Nederland
          </h1>
          <img
            src={dummy}
            alt="dummy"
            className="w-[90%] rounded-[15px] z-10 relative"
          />
          <p className="font-Poppins text-[16px] leading-[24px] font-medium text-white w-[90%] text-left">
            Befesti ontwikkelt zich snel van een marktplaats voor aanbod en
            vraag naar een platform dat een onmisbare rol speelt in de hele
            wereld volledige klantreis van onze gebruikers. Groei met ons mee en
            laat uw merk spelen een relevante rol tijdens de bijzondere fase
            waarin onze bezoekers zich bevinden in.
          </p>
          <div className="flex flex-row items-start justify-start w-[90%] gap-5 z-10">
            <div className="relative overflow-hidden z-50 hidden md:inline-block px-4 py-2 rounded-full text-white uppercase text-base font-semibold select-none cursor-pointer transition-all duration-300 bg-[#ec4899] group">
              <p className="z-50 relative">Alles over de doelgroep</p>
              <span className="absolute bottom-0 left-0 w-full h-0 group-hover:h-full bg-[#1474f4] rounded-full z-0 transition-all duration-300"></span>
            </div>
            <div className="relative overflow-hidden z-50 hidden md:inline-block px-4 py-2 rounded-full text-white uppercase text-base font-semibold select-none cursor-pointer transition-all duration-300 bg-[#ec4899] group">
              <p className="z-50 relative">Gebruikersinzichten uit onderzoek</p>
              <span className="absolute bottom-0 left-0 w-full h-0 group-hover:h-full bg-[#1474f4] rounded-full z-0 transition-all duration-300"></span>
            </div>
          </div>
        </div> */}
            <div className="flex flex-col items-center justify-center gap-2 z-10 w-[90%]">
              <h1 className="font-Bowlby text-[32px] leading-[52px] text-white ">
                Onze diverse kanalen combineren, dé onmisbare schakel
              </h1>
              {/* <p className="font-Poppins text-[16px] leading-[24px] font-medium text-white">
            Samen met u als adverteerder voegen wij waarde toe voor iedereen op
            zoek naar een huis of inspiratie. Terwijl funda een compleet pakket
            biedt aanbod en hoogwaardige informatie, waarmee je onze bezoekers
            inspireert relevante en stimulerende campagnes.
          </p> */}
              <div className="md:flex flex-row flex-wrap md:w-fit w-full items-stretch justify-center gap-8  md:pt-3 pt-1 pb-3 h-full">
                <SmallCard
                  heading="Instagram"
                  text="Bereik onze loyale volgers via posts en stories. Bouw naamsbekendheid op of boost je ticketsale."
                />
                <SmallCard
                  heading="TikTok"
                  text="Vergroot je bekendheid bij onze jongere doelgroep en bereik nieuwe personen. "
                />
                <SmallCard
                  heading="Website"
                  text="Op het juiste moment, op de juiste plek. Zichtbaar zijn op het moment dat bezoekers op zoek zijn naar feestjes. Ideaal voor conversie en ticketverkoop."
                />
              </div>
            </div>
            {/* <div className="flex flex-col items-start justify-center gap-2 z-10 w-[90%]">
          <h1 className="font-Bowlby text-[32px] leading-[52px] text-white ">
            Verdiep je verder
          </h1>
          <p className="font-Poppins text-[16px] leading-[24px] font-medium text-white">
            Samen met u als adverteerder voegen wij waarde toe voor iedereen op
            zoek naar een huis of inspiratie. Terwijl funda een compleet pakket
            biedt aanbod en hoogwaardige informatie, waarmee je onze bezoekers
            inspireert relevante en stimulerende campagnes.
          </p>
          <div className="flex flex-row items-start justify-start gap-8 flex-wrap py-8">
            <Card
              heading="Doelgroepen"
              text="Targeting met behulp van Machine Learning en AI"
              link="Meer informatie"
              src={dummy}
              maxWidth="480px"
              imgHeight="300px"
            />
            <Card
              heading="Uniek inzicht in de funda bezoeker"
              text="Een inkijkje in de wensen van jouw doelgroep"
              link="Meer informatie"
              src={dummy}
              maxWidth="480px"
              imgHeight="300px"
            />
            <Card
              heading="Doelgroepen"
              text="Targeting met behulp van Machine Learning en AI"
              link="Meer informatie"
              src={dummy}
              maxWidth="480px"
              imgHeight="300px"
            />
            <Card
              heading="Uniek inzicht in de funda bezoeker"
              text="Een inkijkje in de wensen van jouw doelgroep"
              link="Meer informatie"
              src={dummy}
              maxWidth="480px"
              imgHeight="300px"
            />
          </div>
        </div> */}
            <div className="w-full z-10 bg-[#A6DCFF] flex flex-col items-center justify-center py-16 gap-4">
              <div className="flex flex-row items-center justify-center w-[90%] max-w-[1536px] gap-5 z-10">
                <div className="block w-full">
                  <div className="flex flex-col items-start justify-center gap-2">
                    <h1 className="font-Bowlby text-[32px] leading-[52px] text-center w-full text-white">
                      Klaar om jouw event te boosten?
                    </h1>
                    <p className="whitespace-nowrap font-Poppins text-[16px] leading-[24px] font-medium text-white text-center w-full">
                      Quinten helpt je verder 🤝
                    </p>
                  </div>

                  <div
                    ref={contactRef}
                    className="flex flex-col items-start justify-center w-full z-20 gap-4 mt-10"
                  >
                    <div className="flex md:flex-row flex-col items-start justify-start w-full gap-2">
                      <div className="flex flex-col items-start justify-start w-full">
                        <input
                          type="text"
                          placeholder="Naam"
                          className="focus:outline-0 w-[100%] h-[50px] rounded-lg p-4 bg-transparent border-2 border-white placeholder:text-white text-white font-Poppins text-xl"
                          value={Name}
                          onChange={(e) => setName(e.target.value)}
                        />
                        {errors.name && (
                          <p className="text-red-500 text-sm">{errors.name}</p>
                        )}
                      </div>
                      <div className="flex flex-col items-start justify-start w-full">
                        <input
                          type="email"
                          placeholder="Email"
                          className="focus:outline-0 w-[100%] h-[50px] rounded-lg p-4 bg-transparent border-2 border-white placeholder:text-white text-white font-Poppins text-xl"
                          value={Email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                        {errors.email && (
                          <p className="text-red-500 text-sm">{errors.email}</p>
                        )}
                      </div>
                    </div>
                    <div className="flex md:flex-row flex-col items-start justify-start w-full gap-2">
                      <div className="flex flex-col items-start justify-start w-full">
                        <input
                          type="text"
                          placeholder="Naam event"
                          className="focus:outline-0 w-[100%] h-[50px] rounded-lg p-4 bg-transparent border-2 border-white placeholder:text-white text-white font-Poppins text-xl"
                          value={festival}
                          onChange={(e) => setFestival(e.target.value)}
                        />
                        {errors.festival && (
                          <p className="text-red-500 text-sm">
                            {errors.festival}
                          </p>
                        )}
                      </div>
                      <div className="flex flex-col items-start justify-start w-full">
                        <input
                          type="text"
                          placeholder="Phone number"
                          className="focus:outline-0 w-[100%] h-[50px] rounded-lg p-4 bg-transparent border-2 border-white placeholder:text-white text-white font-Poppins text-xl"
                          value={Contact}
                          onChange={(e) => setContact(e.target.value)}
                        />
                        {errors.contact && (
                          <p className="text-red-500 text-sm">
                            {errors.contact}
                          </p>
                        )}
                      </div>
                    </div>
                    <textarea
                      type="text"
                      placeholder="Bericht"
                      rows={8}
                      className="focus:outline-0 w-[100%] rounded-lg p-4 bg-transparent border-2 border-white placeholder:text-white text-white font-Poppins text-xl resize-none "
                      value={Message}
                      onChange={(e) => setMessage(e.target.value)}
                    />
                    {errors.message && (
                      <p className="text-red-500 text-sm">{errors.message}</p>
                    )}
                    <div className="flex flex-row justify-center items-center w-full md:w-fit">
                      <div
                        className="relative overflow-hidden z-50 md:inline-block px-4 py-2 rounded-md text-white font-Poppins text-base font-bold cursor-pointer transition-all duration-300 bg-[#ec4899] group"
                        onClick={handleSubmit}
                      >
                        <p className="z-50 relative">Verstuur</p>
                        <span className="absolute bottom-0 left-0 w-full h-0 group-hover:h-full bg-[#1474f4] rounded-md z-0 transition-all duration-300"></span>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <img
              src={dummy}
              alt="dummy"
              className="w-[300px] h-[300px] rounded-full z-10 object-cover md:block hidden"
            /> */}
              </div>
            </div>
          </div>
        </HomeLayout>
      )}
    </>
  );
};

export default PromoteEvent;
