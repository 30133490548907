/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import HomeLayout from "../../layouts/HomeLayout";
import eighteen from "../../assets/eighteen.png";
import { IoLocationSharp } from "react-icons/io5";
import { FaMusic } from "react-icons/fa";
import { FaCalendar } from "react-icons/fa";
import { FaClock } from "react-icons/fa";
import festImg from "../../assets/festival.png";
import { useLocation } from "react-router-dom";
import { GetFestivalDetails, GetFestivalArtists } from "../../Client/request";
import TicketSwap from "../../assets/TicketSwap.png";
import { genreColors } from "../../constants/Genre";

import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

// function convertToGMT(timeString) {
//   const date = new Date(`2000-01-01T${timeString}`);
//   const offsetHours = date.getTimezoneOffset() / 60;
//   const offsetSign = offsetHours >= 0 ? "+" : "-";
//   const offsetAbs = Math.abs(offsetHours).toString().padStart(2, "0");
//   return `${timeString} GMT${offsetSign}${offsetAbs}`;
// }

const FestivalDetails = () => {
  const [festival, setFestival] = useState({});
  const [artists, setArtists] = useState([]);
  const [showEndDate, setshowEndDate] = useState();
  const [loading, setLoading] = useState(false);
  const [fromTIME, setfromTIME] = useState();
  const [endTIME, setendTIME] = useState();
  const [pageLoading, setPageLoading] = useState(true);
  // const [startTimeGMT, setstartTimeGMT] = useState();
  // const [endTimeGMT, setendTimeGMT] = useState();

  const getFestival = async (id) => {
    setLoading(true);
    await GetFestivalDetails(id).then(async (res) => {
      setLoading(false);
      setFestival(res);
      await GetFestivalArtists(res.FestivalID).then((res) => {
        setArtists(res);
      });
    });
  };

  const navigate = useNavigate();

  const location = useLocation();

  useEffect(() => {
    const name = location.pathname.split("/")[2];
    getFestival(name.replace(/_/g, "%20"));
  }, []);

  useEffect(() => {
    // after 2 seconds set pageLoading to false
    setTimeout(() => {
      setPageLoading(false);
    }, 400);
  }, []);

  useEffect(() => {
    const duration = new Date(festival.EndDate) - new Date(festival.FromDate);
    const durationInHours = duration / (1000 * 60 * 60);
    setshowEndDate(durationInHours > 14);
    console.log("HELLO", fromTIME);
    if (festival.FromTime && festival.EndTime) {
      setfromTIME(festival.FromTime.replace(/:00(?!.*:00)/, ""));
      console.log("HELLO1", fromTIME);
      setendTIME(festival.EndTime.replace(/:00(?!.*:00)/, ""));
    }
    setFestival(festival);
  }, [festival]);

  const meta = { __html: festival.MetaDescription };

  return (
    <>
      {pageLoading ? (
        <>
          {/* loader */}
          <div className="flex items-center justify-center h-screen w-screen bg-[#5ACCFF]">
            <div className="flex items-center justify-center flex-col">
              <h1 className="text-white font-Bowlby text-2xl md:text-5xl">
                {festival.Name}
              </h1>
              <div className="flex items-center justify-center">
                <div className="animate-spin rounded-full h-20 md:h-32 w-20 md:w-32 border-t-2 border-[#fff]"></div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <HomeLayout>
          <div className="flex flex-col items-center justify-start w-full mb-10 md:mt-40 mt-28 gap-4">
            <div className="w-[90%] flex flex-row items-center justify-between">
              <div className="flex flex-row items-start justify-start gap-4">
                {/* <img
              src={festival.FestivalImage || festImg}
              alt="festival"
              className="w-16 h-16 md:w-[100px] md:h-[100px] rounded-[8px] object-cover"
            /> */}
                <p className="font-Bowlby md:text-[44px] font-bold text-white md:leading-[52px] text-2xl">
                  {loading ? (
                    <Skeleton width={200} height={40} />
                  ) : (
                    festival.Name
                  )}
                </p>
              </div>
              <div className="md:flex flex-row items-center justify-center gap-4">
                <div className="w-full flex flex-row justify-center items-center">
                  <img
                    src={TicketSwap}
                    alt="ticketswap"
                    className="md:w-[26px] w-5 cursor-pointer"
                    onClick={() => {
                      window.open(
                        festival.TicketsURL.includes("https://www.")
                          ? festival.TicketsURL
                          : `https://www.${festival.TicketsURL}`,
                        "_blank"
                      );
                    }}
                  />
                </div>
                <div
                  className=" md:mt-0 mt-1 relative overflow-hidden z-10 inline-block md:px-4 md:py-2 px-2 py-1 rounded-full text-white uppercase md:text-base text-[8px] cursor-pointer transition-all duration-300 bg-[#ec4899] group min-w-[130px]"
                  onClick={() => {
                    const tab = window.open(
                      festival.TicketsURL.includes("https://www.")
                        ? festival.SpecificTicketURL
                        : `https://www.${festival.SpecificTicketURL}`,
                      "_blank",
                      "noopener, noreferrer"
                    );
                    tab !== null && tab.focus();
                  }}
                >
                  <p className="z-50 relative font-Poppins font-medium w-full text-center">
                    Ticketshop
                  </p>
                  <span className="absolute bottom-0 left-0 w-full h-0 group-hover:h-full bg-[#1474f4] rounded-full z-0 transition-all duration-300"></span>
                </div>
              </div>
            </div>
            <div className="flex md:flex-row flex-col md:items-start items-center md:justify-between justify-center gap-8 w-full md:w-[90%] mt-4">
              <div className="flex flex-col items-start justify-start gap-4 bg-white bg-opacity-50 p-4 md:w-[35%] w-[90%] rounded-[8px]">
                <p className="font-Bowlby font-medium text-[24px] text-white">
                  Datum & Tijd
                </p>
                <div className="flex flex-row items-center justify-start gap-4">
                  <FaCalendar className="text-white" />
                  {loading ? (
                    <Skeleton width={200} height={20} />
                  ) : (
                    <p className="font-Poppins md:text-[20px] md:leading-[28px] text-base font-medium text-white">
                      {new Date(festival.FromDate).toLocaleDateString("nl-NL", {
                        weekday: "long",
                        month: "short",
                        day: "2-digit",
                        year: "numeric"
                      })}
                      {showEndDate && (
                        <>
                          {" "}
                          -{" "}
                          {new Date(festival.EndDate).toLocaleDateString(
                            "nl-NL",
                            {
                              weekday: "long",
                              month: "short",
                              day: "2-digit",
                              year: "numeric"
                            }
                          )}
                        </>
                      )}
                    </p>
                  )}
                </div>
                <div className="flex flex-row items-center justify-start gap-4">
                  <FaClock className="text-white" />
                  {loading ? (
                    <Skeleton width={200} height={20} />
                  ) : (
                    <p className="font-Poppins md:text-[20px] md:leading-[28px] text-base font-medium text-white">
                      {/* {new Date(festival.FromDate)
                  .toLocaleTimeString("en-US", { hour12: false })
                  .slice(0, -3) +
                  " GMT" +
                  (new Date(festival.FromDate).getTimezoneOffset() <= 0
                    ? "+"
                    : "-") +
                  Math.abs(new Date(festival.FromDate).getTimezoneOffset() / 60)
                    .toString()
                    .padStart(2, "0")}{" "}
                -{" "}
                {new Date(festival.EndDate)
                  .toLocaleTimeString("en-US", { hour12: false })
                  .slice(0, -3) +
                  " GMT" +
                  (new Date(festival.EndDate).getTimezoneOffset() <= 0
                    ? "+"
                    : "-") +
                  Math.abs(new Date(festival.EndDate).getTimezoneOffset() / 60)
                    .toString()
                    .padStart(2, "0")} */}
                      {/* {startTimeGMT} {" - "} {endTimeGMT} */}
                      {fromTIME} {" - "} {endTIME}
                    </p>
                  )}
                </div>
                <div className="w-full h-[2px] bg-white bg-opacity-50"></div>
                <p className="font-Bowlby font-medium text-[24px] text-white">
                  GENRE
                </p>
                <div className="flex flex-row items-center justify-start gap-4">
                  <FaMusic className="text-white" />
                  {loading ? (
                    <div className="flex flex-row items-center justify-start gap-4 flex-wrap">
                      <Skeleton width={100} height={20} />
                      <Skeleton width={100} height={20} />
                      <Skeleton width={100} height={20} />
                    </div>
                  ) : (
                    <div className="flex flex-row items-center justify-start gap-4 flex-wrap">
                      {festival &&
                        festival?.Genre?.map((genre, index) => (
                          <div
                            key={index}
                            className={`text-xs md:text-base font-Poppins font-bold py-1 px-2 rounded-md flex flex-row items-center justify-center gap-1 text-black `}
                            style={{
                              backgroundColor: genreColors.find(
                                (g) => g.genre === genre.toLowerCase()
                              ).color
                            }}
                          >
                            <span>{genre}</span>
                          </div>
                        ))}
                    </div>
                  )}
                </div>

                <div className="w-full h-[2px] bg-white bg-opacity-50"></div>
                <p className="font-Bowlby font-medium text-[24px] text-white">
                  LOCATIE
                </p>
                <div className="flex flex-row items-center justify-start gap-4">
                  <IoLocationSharp className="text-white" />
                  {loading ? (
                    <Skeleton width={250} height={20} />
                  ) : (
                    <p className="font-Poppins md:text-[20px] md:leading-[28px] text-base font-medium text-white">
                      {festival.Land}, {festival.Locatie}, {festival.Location}
                    </p>
                  )}
                </div>
              </div>
              <div className="flex flex-col items-center justify-start gap-4 md:w-[65%] w-[90%]">
                <div className="flex md:flex-row flex-col md:items-center items-start justify-start bg-white bg-opacity-50 p-2 w-full rounded-[8px]">
                  <p className="font-Bowlby  leading-[36px] text-[24px] px-2  text-white">
                    Beschrijving
                  </p>
                </div>
                <div className="flex flex-col items-center justify-center w-full">
                  <p className="w-full font-Poppins md:text-[20px] md:leading-[28px] text-base font-medium text-white">
                    {loading ? (
                      <Skeleton count={3} />
                    ) : (
                      festival.FestivalDescription
                    )}
                  </p>
                </div>
                <div className="flex md:flex-row flex-col md:items-center items-start justify-start bg-white bg-opacity-50 p-2 w-full rounded-[8px]">
                  <p className="font-Bowlby  leading-[36px] text-[24px] px-2  text-white">
                    Tickets
                  </p>
                  {/* <div className="flex flex-row justify-center gap-4">
            <Buybutton>Buy Ticket</Buybutton>

            <Morebutton>More Info</Morebutton>
          </div> */}
                </div>
                {loading ? (
                  <div className="flex flex-col items-center justify-center w-full">
                    <p className="w-full font-Poppins md:text-[20px] md:leading-[28px] text-base font-medium text-white">
                      <Skeleton count={3} />
                    </p>
                  </div>
                ) : (
                  <div
                    className="flex flex-col items-start justify-center w-full font-Poppins md:text-[20px] md:leading-[28px] text-base font-medium text-white"
                    dangerouslySetInnerHTML={meta}
                  ></div>
                )}
                {Array.isArray(artists) && artists.length !== 0 && (
                  <>
                    <div className="flex flex-row items-center justify-start bg-white bg-opacity-50 p-2 w-full rounded-[8px]">
                      <p className="font-Bowlby  leading-[36px] text-[24px] px-2  text-white">
                        Line-Up
                      </p>
                    </div>
                    <div className="flex flex-col items-center justify-center w-full">
                      <p className="w-full font-Poppins md:text-[20px] md:leading-[28px] text-base font-medium text-white">
                        De volledige line-up is bekend. Bekijk de artiesten per
                        podium hier:
                      </p>
                      <ul className="list-disc list-inside w-full">
                        {Array.isArray(artists) &&
                          artists.map((artist) => (
                            <li
                              className="w-full font-Poppins md:text-[20px] md:leading-[28px] text-base font-medium text-white"
                              key={artist.ArtistId}
                            >
                              {artist.ArtistName}
                            </li>
                          ))}
                      </ul>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </HomeLayout>
      )}
    </>
  );
};

export default FestivalDetails;
