/* eslint-disable jsx-a11y/img-redundant-alt */
import React from "react";

const SmallCard = ({ heading, text }) => {
  return (
    <div
      className={`md:mt-0 mt-3 flex-grow flex-1 flex flex-col items-start justify-start gap-4 rounded-[12px] bg-[#A6DCFF] overflow-hidden hover:scale-105 transition-all duration-300 md:max-w-[330px] h-auto p-4`}
    >
      <h1 className="font-Bowlby text-white text-[36px] leading-[44px]">
        {heading}
      </h1>
      <p className="font-Poppins text-white text-[16px] leading-[24px]">
        {text}
      </p>
    </div>
  );
};

export default SmallCard;
