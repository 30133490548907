export const months = (() => {
  const currentMonth = new Date().getMonth();
  const allMonths = [
    "Januari",
    "Februari",
    "Maart",
    "April",
    "Mei",
    "Juni",
    "Juli",
    "Augustus",
    "September",
    "Oktober",
    "November",
    "December"
  ];
  return allMonths.slice(currentMonth).concat(allMonths.slice(0, currentMonth));
})();
export const Genre = [
  "Techno",
  "Hardstyle",
  "Hardcore",
  "House",
  "Pop",
  "Urban",
  "Trance"
];
export const Location = [
  "Amsterdam",
  "Rotterdam",
  "Utrecht",
  "Noord-Brabant",
  "Noord-Holland",
  "Zuid-Holland",
  "Gelderland",
  "Groningen",
  "Limburg",
  "Flevoland",
  "Drenthe",
  "Overijssel",
  "Zeeland",
  "Friesland",
  "België"
];
export const age = ["Younger", "Mixed", "Older", "Unknown"];
export const Price = ["Gratis", "<€30 euro", "€30 - €60 euro", ">€60 euro"];
export const day = ["MON", "TUE", "WED", "THU", "FRI", "SAT", "SUN"];
export const festivals = ["Festivals", "Events"];
