/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import Navbar from "../../Components/Navbar";
import BG from "../../assets/BG.png";
import Main from "../../assets/Main.png";
import BG2 from "../../assets/BG2.png";
import Footer from "../../Components/Footer";
// import WatchLaterIcon from "@mui/icons-material/WatchLater";
// import Location1OnIcon from "@mui/icons-material/Location1On";
// import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import { Button, Divider, styled } from "@mui/material"; // Import CircularProgress
// import swap from "../../assets/swap.png";
import HomeForm from "../../modules/HomeForm";
import { GetFestivals } from "../../Client/request";
import FestivalCard from "./FestivalCard";
import { IoCloseCircleOutline } from "react-icons/io5";
import { Genre } from "../../constants/Genre";
import { AddSubscriber } from "../../Client/request";
import { UseDispatch, useDispatch } from "react-redux";
import { setMonth } from "../../store/monthSlice";

import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const Buybutton = styled(Button)({
  boxShadow: "none",
  fontSize: "20px",
  lineHeight: "36px",
  backgroundColor: "#ffffff",
  fontfamily: "Poppins",
  textTransform: "none",
  fontWeight: 600,
  color: "#262625",
  width: "144px",
  padding: "3px 10px",
  // height: "52px",
  borderRadius: "100px",
  "&:hover": {
    fontSize: "20.2px",
    backgroundColor: "lightblue"
  }
});

const Morebutton = styled(Button)({
  boxShadow: "none",
  fontSize: "20px",
  lineHeight: "36px",
  backgroundColor: "transparent",
  fontfamily: "Poppins",
  textTransform: "none",
  border: "2px solid #ffffff",
  fontWeight: 600,
  color: "white",
  width: "144px",
  padding: "3px 10px",
  // height: "52px",
  borderRadius: "100px",
  "&:hover": {
    fontSize: "20.2px",
    backgroundColor: "lightblue"
  }
});

const h1_style = {
  fontSize: "90px",
  fontFamily: "Bowlby One SC",
  fontWeight: 400,
  lineHeight: "99px",
  textAlign: "center",
  color: "white",
  position: "relative",
  zIndex: "15",
  paddingTop: "80px"
};

const h2_style = {
  color: "white",
  fontFamily: "Bowlby One SC",
  fontweight: 400,
  fontSize: "32px",
  lineHeight: "52px"
};

const bg2 = {
  background: `url(${BG2}) #B3DDFF 0px -140.106px / 100% 269.003% no-repeat`
};

const bg3 = {
  background: `url(${BG2}) #B3DDFF 0px -2449.165px / 100% 269.003% no-repeat`
};

export default function Home() {
  const [sortedMonths, setSortedMonths] = useState({});
  const [festivals, setFestivals] = useState([]);
  const [payload, setPayload] = useState({});
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState(false);
  const [trigger, setTrigger] = useState(false);
  const [showAllFestivals, setShowAllFestivals] = useState(false);
  const [location1, setLocation1] = useState({
    latitude: null,
    longitude: null
  });
  const [nearBy, setNearBy] = useState([]);
  const [popUp, setPopUp] = useState(false);
  const [nearLoading, setNearLoading] = useState(true);
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [genre, setGenre] = useState("");
  const [genreError, setGenreError] = useState(false);
  const [filterApplied, setFilterApplied] = useState(false);
  const [currentMonthFav, setCurrentMonthFav] = useState({});
  const [currentMonthOnly, setCurrentMonthOnly] = useState(false);
  const [nextMonthOnly, setNextMonthOnly] = useState(false);
  const [currentWeekOnly, setCurrentWeekOnly] = useState(false);
  const [pageLoading, setPageLoading] = useState(true);
  const [festivalsLoading, setFestivalsLoading] = useState(false);
  const [dontLoad, setDontLoad] = useState(false);

  let thirdMonth;

  useEffect(() => {
    // after 2 seconds set pageLoading to false
    setTimeout(() => {
      setPageLoading(false);
    }, 400);
  }, []);

  const addSub = async () => {
    const data = {
      email: email,
      genre: genre
    };
    const response = await AddSubscriber(data);
    console.log(response);
  };

  useEffect(() => {
    GetFestivals().then((res) => {
      if (res.error) {
        setFestivals([]);
        setLoading(false);
        return;
      }
      setFestivals(res);
      const sortedFestival = {};

      // Sort the filtered festivals by their FromDate in ascending order
      sortFestivals(res)?.forEach((item) => {
        if (!item.IsPromoted) {
          const month = item.Month;
          const duration = new Date(item.EndDate) - new Date(item.FromDate);
          const durationInHrs = duration / (1000 * 60 * 60);
          item.duration = durationInHrs;
          if (!sortedFestival[month]) {
            sortedFestival[month] = [];
          }
          sortedFestival[month].push(item);
        }
      });

      const sortedFestivalObjects = Object.fromEntries(
        Object.entries(sortedFestival).map(([key, value]) => [key, value])
      );

      setPayload(res);
      setLoading(false);
      setSortedMonths(sortedFestivalObjects);

      getCurrentMonthFav();

      const monthsWithFestivals = Object.keys(sortedFestivalObjects);
      thirdMonth = monthsWithFestivals.findIndex((month, index) => {
        return index === 2 && sortedFestivalObjects[month].length > 0;
      });
    });
  }, [trigger]);

  const sortAndGroupFestivalsByMonth = (festivals) => {
    // Sort festivals by date
    const sortedFestivals = festivals.sort(
      (a, b) => new Date(a.FromDate) - new Date(b.FromDate)
    );

    // Group sorted festivals by their month
    const groupedByMonth = sortedFestivals.reduce((acc, festival) => {
      const month = festival.Month; // Assuming festival.Month is a string representing the month
      if (!acc[month]) {
        acc[month] = [];
      }
      acc[month].push(festival);
      return acc;
    }, {});

    return groupedByMonth;
  };

  // if there are some filters in query params as month=januari,februari&genre=rock etc apply them
  useEffect(() => {
    // This function applies filters based on current query params and updates the state
    const applyFiltersFromQueryParams = () => {
      const urlParams = new URLSearchParams(window.location.search);
      let filteredFestivals = [...festivals]; // Start with all festivals

      // Apply month filter
      const month = urlParams.get("month");
      if (month) {
        const months = month.split(",");
        filteredFestivals = filteredFestivals.filter((festival) =>
          months.includes(festival.Month)
        );
      }

      // Apply genre filter
      const genre = urlParams.get("genre");
      if (genre) {
        const genres = genre.split(",");
        filteredFestivals = filteredFestivals.filter((festival) =>
          genres.includes(festival.Genre)
        );
      }

      // Further filters (location, price, etc.) can be applied in a similar fashion...

      // Now, sort and group the filtered festivals by months or any other criteria as required
      const sortedAndGroupedFestivals =
        sortAndGroupFestivalsByMonth(filteredFestivals);
      setSortedMonths(sortedAndGroupedFestivals);
    };

    // Call the filter application function
    applyFiltersFromQueryParams();
  }, [festivals, window.location.search]); // Rerun this effect if festivals list or URL search params change

  // Assuming sortAndGroupFestivalsByMonth is a function you define to sort and optionally group your festivals by month or any other criteria after filtering

  useEffect(() => {
    const sortedFestival = {};
    sortFestivals(festivals).forEach((item) => {
      const month = item.Month;
      if (!sortedFestival[month]) {
        sortedFestival[month] = [];
      }
      sortedFestival[month].push(item);
    });

    const sortedFestivalObjects = Object.fromEntries(
      Object.entries(sortedFestival).map(([key, value]) => [key, value])
    );

    setSortedMonths(sortedFestivalObjects);
  }, [currentMonthOnly, nextMonthOnly, currentWeekOnly]);

  const getCurrentMonthFav = () => {
    const currentDate = new Date();
    const monthName = currentDate.toLocaleString("nl-NL", { month: "long" });
    const monthFav = festivals.filter((festival) => {
      // return festival.Month.toLowerCase() === monthName && festival.IsFavourite;
      return festival.IsFavourite;
    });

    console.log(monthFav);

    // const monthFavSorted = monthFav.filter((festival) => {
    //   return festival.FromDate >= currentDate;
    // });

    const groupedByMonth = sortAndGroupFestivalsByMonth(monthFav);
    console.log(Object.entries(groupedByMonth));

    setCurrentMonthFav(groupedByMonth);
    // setCurrentMonthFav(monthFavSorted);
  };

  const sortFestivals = (festivals) => {
    festivals.forEach((festival) => {
      const duration = new Date(festival.EndDate) - new Date(festival.FromDate);
      const durationInHrs = duration / (1000 * 60 * 60);
      festival.duration = durationInHrs;
      festival.FromDate = new Date(festival.FromDate);
    });

    // Get the current month and year
    const currentDate = new Date();
    const currentMonth = currentDate.toLocaleString("nl-NL", {
      month: "long",
      locale: "nl-NL"
    }); // Current month in Dutch
    const currentYear = currentDate.getFullYear();

    const monthNamesInDutch = [
      "januari",
      "februari",
      "maart",
      "april",
      "mei",
      "juni",
      "juli",
      "augustus",
      "september",
      "oktober",
      "november",
      "december"
    ];
    const currentMonthIndex = monthNamesInDutch.indexOf(
      currentMonth.toLowerCase()
    ); // Index starts from 0

    // Filter out festivals starting from the current month and later
    const filteredFestivals = festivals.filter((festival) => {
      const festivalMonthInDutch = festival.Month.toLowerCase(); // Convert festival month to lowercase Dutch name
      const festivalMonthIndex =
        monthNamesInDutch.indexOf(festivalMonthInDutch); // Index starts from 0
      const festivalYear = festival.FromDate.getFullYear();

      // Compare festival year and month with current year and month
      return (
        festivalYear > currentYear ||
        (festivalYear === currentYear &&
          festivalMonthIndex >= currentMonthIndex)
      );
    });

    if (currentMonthOnly) {
      // Filter out festivals starting from the current month and later
      const filteredFestivals = festivals.filter((festival) => {
        const festivalMonthInDutch = festival.Month.toLowerCase(); // Convert festival month to lowercase Dutch name
        const festivalMonthIndex =
          monthNamesInDutch.indexOf(festivalMonthInDutch); // Index starts from 0
        const festivalYear = festival.FromDate.getFullYear();

        // Compare festival year and month with current year and month
        return (
          festivalYear === currentYear &&
          festivalMonthIndex === currentMonthIndex
        );
      });

      // Sort the filtered festivals
      filteredFestivals.sort((a, b) => {
        return a.FromDate - b.FromDate;
      });

      return filteredFestivals.filter((festival) => {
        return festival.FromDate >= currentDate;
      });
    }

    if (nextMonthOnly) {
      // Filter out festivals starting from the next month
      const filteredFestivals = festivals.filter((festival) => {
        const festivalMonthInDutch = festival.Month.toLowerCase(); // Convert festival month to lowercase Dutch name
        const festivalMonthIndex =
          monthNamesInDutch.indexOf(festivalMonthInDutch); // Index starts from 0
        const festivalYear = festival.FromDate.getFullYear();

        // Compare festival year and month with current year and month
        return (
          festivalYear === currentYear &&
          festivalMonthIndex === currentMonthIndex + 1
        );
      });

      // Sort the filtered festivals
      filteredFestivals.sort((a, b) => {
        return a.FromDate - b.FromDate;
      });
      return filteredFestivals;
    }

    if (currentWeekOnly) {
      // Filter out festivals starting from the current month and later
      const filteredFestivals = festivals.filter((festival) => {
        const festivalMonthInDutch = festival.Month.toLowerCase(); // Convert festival month to lowercase Dutch name
        const festivalMonthIndex =
          monthNamesInDutch.indexOf(festivalMonthInDutch); // Index starts from 0
        const festivalYear = festival.FromDate.getFullYear();

        // Compare festival year and month with current year and month
        return (
          festivalYear === currentYear &&
          festivalMonthIndex === currentMonthIndex &&
          festival.FromDate >= currentDate
        );
      });

      // Sort the filtered festivals
      filteredFestivals.sort((a, b) => {
        return a.FromDate - b.FromDate;
      });

      return filteredFestivals.filter((festival) => {
        return festival.FromDate >= currentDate;
      });
    }

    // Sort the filtered festivals
    filteredFestivals.sort((a, b) => {
      const monthA = monthNamesInDutch.indexOf(a.Month.toLowerCase());
      const monthB = monthNamesInDutch.indexOf(b.Month.toLowerCase());

      // Compare months
      if (monthA === monthB) {
        // If months are the same, compare dates
        return a.FromDate - b.FromDate;
      } else {
        // Otherwise, compare months
        return monthA - monthB;
      }
    });

    const sortedFestivals = filteredFestivals.filter((festival) => {
      return festival.FromDate >= currentDate;
    });

    return sortedFestivals;
  };

  useEffect(() => {
    getCurrentMonthFav();
  }, [festivals]);

  useEffect(() => {
    setTimeout(() => {
      festivalsLoading && setFestivalsLoading(false);
      setPopUp(true);
    }, 20000);
  }, []);

  useEffect(() => {
    getNearBy();
  }, [location1, festivals]);

  function calculateDistance(lat2, lon2) {
    const lat1 = location1.latitude;
    const lon1 = location1.longitude;
    const R = 6371; // Radius of the Earth in kilometers
    const dLat = ((lat2 - lat1) * Math.PI) / 180; // Convert degrees to radians
    const dLon = ((lon2 - lon1) * Math.PI) / 180;
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos((lat1 * Math.PI) / 180) *
        Math.cos((lat2 * Math.PI) / 180) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const distance = R * c; // Distance in kilometers
    return distance <= 50;
  }

  const getNearBy = () => {
    setNearBy([]);
    for (let i = 0; i < festivals.length; i++) {
      if (
        calculateDistance(
          parseFloat(festivals[i].XCoordinates),
          parseFloat(festivals[i].YCoordinates)
        )
      ) {
        console.log(festivals[i]);
        if (!nearBy.includes(festivals[i])) {
          setNearBy((prev) => [...prev, festivals[i]]);
        }
      }
    }
    setNearLoading(false);
  };

  const applyFilters = (newSortedMonths) => {
    const sortedFestival = {};
    sortFestivals(newSortedMonths).forEach((item) => {
      const month = item.Month;
      if (!sortedFestival[month]) {
        sortedFestival[month] = [];
      }
      sortedFestival[month].push(item);
    });
    const sortedFestivalObjects = Object.fromEntries(
      Object.entries(sortedFestival).map(([key, value]) => [key, value])
    );
    dontLoad && setFestivalsLoading(true);
    setTimeout(() => {
      setFestivalsLoading(false);
    }, 1000);
    setSortedMonths(sortedFestivalObjects);
    // dontLoad && setShowAllFestivals(true);
  };

  const handleShowAllFestivals = () => {
    setShowAllFestivals(true);
  };

  useEffect(() => {
    !pageLoading &&
      Object.entries(payload).length !== 0 &&
      document.getElementById("filterButton").click();
    !pageLoading && Object.entries(payload).length !== 0 && setDontLoad(true);
  }, [payload, pageLoading]);

  useEffect(() => {
    if (filterApplied) {
      applyFilters(payload);
    }
  }, [filterApplied]);

  const dispatch = useDispatch();

  return (
    <>
      {pageLoading && (
        <>
          {/* loader */}
          <div className="flex items-center justify-center h-screen w-screen bg-[#5ACCFF]">
            <div className="flex items-center justify-center flex-col">
              <h1 className="text-white font-Bowlby md:text-5xl text-2xl">
                Festival Agenda
              </h1>
              <div className="flex items-center justify-center">
                <div className="animate-spin rounded-full md:h-32 md:w-32 w-20 h-20 border-t-2 border-[#fff]"></div>
              </div>
            </div>
          </div>
        </>
      )}
      {!pageLoading && (
        <>
          {" "}
          <div
            className={`fixed z-50 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white rounded-lg shadow-2xl ${
              popUp ? " w-[300px]  md:w-[500px]" : "w-0"
            } transition-all duration-300 ease-in-out overflow-hidden`}
          >
            {popUp && (
              <div className="flex flex-col items-start justify-center w-full gap-4 p-4">
                <div className="flex justify-between items-center w-full">
                  <h1 className="text-2xl font-Bowlby w-full text-center">
                    Win GRATIS Festival Tickets!
                  </h1>
                  <IoCloseCircleOutline
                    onClick={() => setPopUp(false)}
                    className="text-2xl cursor-pointer"
                  />
                </div>
                <p className="text-lg font-Poppins">
                  Maak kans op gratis festival tickets door je nu aan te melden
                  voor de Festival Agenda nieuwsbrief!
                </p>
                <div className="flex flex-col items-center justify-center w-full gap-2">
                  <input
                    type="email"
                    placeholder="Email"
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                      setEmailError(false);
                    }}
                    className={`w-full p-2 rounded-md border-2 border-gray-300 focus:outline-0 ${
                      emailError && "border-red-500"
                    }`}
                  />
                  {/* genere select input including no preference and all generes */}
                  <select
                    className="w-full p-2 rounded-md border-2 border-gray-300 font-Poppins"
                    name="genre"
                    id="genre"
                    value={genre}
                    onChange={(e) => setGenre(e.target.value)}
                  >
                    <option value="">Favoriete genre</option>
                    {Genre.map((g, index) => (
                      <option key={index} value={g}>
                        {g}
                      </option>
                    ))}
                  </select>
                  {/* show error if no genre is selected */}
                  {genreError && genre === "" && (
                    <p className="text-red-500 text-sm w-full text-left">
                      Please select a genre
                    </p>
                  )}

                  <Button
                    onClick={async () => {
                      if (
                        email === "" ||
                        genre === "" ||
                        genre === "Choose Your Genre"
                      ) {
                        if (email === "") setEmailError(true);
                        if (genre === "") setGenreError(true);
                        return;
                      } else if (
                        !email.includes("@") ||
                        !email.includes(".") ||
                        email.length < 5
                      ) {
                        setEmailError(true);
                        return;
                      } else if (
                        genre === "" ||
                        genre === "Choose Your Genre"
                      ) {
                        setGenreError(true);
                        return;
                      } else {
                        await addSub();
                        setPopUp(false);
                      }
                    }}
                    variant="contained"
                    style={{
                      backgroundColor: "#000",
                      color: "white",
                      fontWeight: "bold",
                      marginTop: "8px"
                    }}
                  >
                    Inschrijven
                  </Button>
                </div>
              </div>
            )}
          </div>
          {festivalsLoading && (
            <>
              {/* loader */}
              <div className="fixed z-50 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 flex items-center justify-center h-screen w-screen">
                <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-[#fff]"></div>
              </div>
            </>
          )}
          <div
            className={`overflow-x-hidden z-0 min-h-screen w-full flex flex-col items-center justify-start transition-all duration-300 ease-in-out bg-[#5ACCFF] ${
              (popUp || festivalsLoading) && "blur-2xl"
            }`}
          >
            <div className="max-w-[2560px] w-full">
              <Navbar setSearch={setSearch} />
              <div className="relative top-0 mt-40 mb-10">
                <img
                  src={Main}
                  alt="main bg"
                  className="absolute z-5 w-full -top-180 left-0"
                />
                <h1
                  className="text-5xl md:text-[90px] font-Bowlby leading-[52px] md:leading-[99px] text-center text-white relative z-10"
                  id="festival"
                >
                  Festival Agenda
                </h1>
                {/* <div className="flex items-center justify-center my-2 w-full">
                  <div className="relative z-10 mt-15  flex justify-center items-center rounded-[15px] bg-white w-[90%] p-4 gap-2">
                    <IoSearch className="text-gray-500 text-lg" />
                    <input
                      type="text"
                      className="font-Poppins text-gray-500 text-sm font-medium w-full focus:outline-0"
                      placeholder="Search festivals and artists"
                    />
                  </div>
                </div> */}
              </div>
              <center>
                <HomeForm
                  payload={payload}
                  onChangeFilters={applyFilters}
                  setTrigger={setTrigger}
                  trigger={trigger}
                  setFilterApplied={setFilterApplied}
                  popUp={popUp}
                />
              </center>
              <div className="flex items-center flex-wrap justify-center gap-4 mt-4 px-3">
                <div
                  className={`relative overflow-hidden z-40  px-4 py-2 rounded-md text-white uppercase text-base font-bold cursor
                  -pointer transition-all duration-300 cursor-pointer ${
                    currentWeekOnly ? "bg-[#1474f4]" : "bg-[#ec4899]"
                  } group`}
                  onClick={() => {
                    setCurrentMonthOnly(false);
                    setNextMonthOnly(false);
                    setCurrentWeekOnly(true);
                    dispatch(
                      setMonth(
                        new Date().toLocaleString("nl-NL", {
                          month: "long",
                          locale: "nl-NL"
                        })
                      )
                    );
                  }}
                >
                  <button className="z-50 relative font-Poppins md:text-[16px] text-[14px]">
                    Deze Week
                  </button>
                  <span
                    className={`absolute bottom-0 left-0 w-full h-0 group-hover:h-full  ${
                      currentWeekOnly ? "bg-[#ec4899]" : "bg-[#1474f4]"
                    } rounded-md z-0 transition-all duration-300`}
                  ></span>
                </div>
                <div
                  className={`relative overflow-hidden z-40  px-4 py-2 rounded-md text-white uppercase text-base font-bold cursor
                  -pointer transition-all duration-300 cursor-pointer ${
                    currentMonthOnly ? "bg-[#1474f4]" : "bg-[#ec4899]"
                  } group`}
                  onClick={() => {
                    setCurrentMonthOnly(true);
                    setNextMonthOnly(false);
                    setCurrentWeekOnly(false);
                    dispatch(
                      setMonth(
                        new Date().toLocaleString("nl-NL", {
                          month: "long",
                          locale: "nl-NL"
                        })
                      )
                    );
                  }}
                >
                  <button className="z-50 relative font-Poppins md:text-[16px] text-[14px] capitalize">
                    {new Date().toLocaleString("nl-NL", {
                      month: "long",
                      locale: "nl-NL"
                    })}
                  </button>
                  <span
                    className={`absolute bottom-0 left-0 w-full h-0 group-hover:h-full  ${
                      currentMonthOnly ? "bg-[#ec4899]" : "bg-[#1474f4]"
                    } rounded-md z-0 transition-all duration-300`}
                  ></span>
                </div>
                <div
                  className={`relative overflow-hidden z-40  px-4 py-2 rounded-md text-white uppercase text-base font-bold cursor
                  -pointer transition-all duration-300 cursor-pointer ${
                    nextMonthOnly ? "bg-[#1474f4]" : "bg-[#ec4899]"
                  } group`}
                  onClick={() => {
                    setCurrentMonthOnly(false);
                    setNextMonthOnly(true);
                    setCurrentWeekOnly(false);
                    dispatch(
                      setMonth(
                        new Date(
                          new Date().setMonth(new Date().getMonth() + 1)
                        ).toLocaleString("nl-NL", {
                          month: "long",
                          locale: "nl-NL"
                        })
                      )
                    );
                  }}
                >
                  <button className="z-50 relative font-Poppins md:text-[16px] text-[14px] capitalize">
                    {new Date(
                      new Date().setMonth(new Date().getMonth() + 1)
                    ).toLocaleString("nl-NL", {
                      month: "long",
                      locale: "nl-NL"
                    })}
                  </button>
                  <span
                    className={`absolute bottom-0 left-0 w-full h-0 group-hover:h-full  ${
                      nextMonthOnly ? "bg-[#ec4899]" : "bg-[#1474f4]"
                    } rounded-md z-0 transition-all duration-300`}
                  ></span>
                </div>
                {/* reset */}
                <div
                  className={`relative overflow-hidden z-40  px-4 py-2 rounded-md text-white uppercase text-base font-bold cursor
                  -pointer transition-all duration-300 cursor-pointer bg-[#ec4899] group`}
                  onClick={() => {
                    setCurrentMonthOnly(false);
                    setNextMonthOnly(false);
                    setCurrentWeekOnly(false);
                    dispatch(setMonth(""));
                  }}
                >
                  <button className="z-50 relative font-Poppins md:text-[16px] text-[14px]">
                    Agenda
                  </button>
                  <span
                    className={`absolute bottom-0 left-0 w-full h-0 group-hover:h-full bg-[#1474f4] rounded-md z-0 transition-all duration-300`}
                  ></span>
                </div>
              </div>
              {loading ? (
                <div className="flex items-center mt-[50px] flex-col">
                  <h1 className="text-white font-Bowlby text-base md:text-[32px] md:leading-[52px] w-[90%] z-10">
                    Festival Agenda
                  </h1>
                  <div
                    style={{ ...bg3 }}
                    className="w-[90%] p-[20px] rounded-[20px] z-10 relative animate-pulse"
                  >
                    <div className="flex items-center justify-center w-full py-4">
                      <div className="flex rounded-full flex-row md:gap-0 gap-4 w-full h-full items-center justify-between p-2 bg-slate-400">
                        <Skeleton
                          circle={true}
                          height={70}
                          width={70}
                          className="md:w-[70px] w-20"
                        />
                        <Skeleton
                          width={100}
                          height={20}
                          className="md:w-[100px] w-40"
                        />
                      </div>
                    </div>
                    <div className="flex items-center justify-center w-full py-4">
                      <div className="flex rounded-full flex-row md:gap-0 gap-4 w-full h-full items-center justify-between p-2 bg-slate-400">
                        <Skeleton
                          circle={true}
                          height={70}
                          width={70}
                          className="md:w-[70px] w-20"
                        />
                        <Skeleton
                          width={100}
                          height={20}
                          className="md:w-[100px] w-40"
                        />
                      </div>
                    </div>
                    <div className="flex items-center justify-center w-full py-4">
                      <div className="flex rounded-full flex-row md:gap-0 gap-4 w-full h-full items-center justify-between p-2 bg-slate-400">
                        <Skeleton
                          circle={true}
                          height={70}
                          width={70}
                          className="md:w-[70px] w-20"
                        />
                        <Skeleton
                          width={100}
                          height={20}
                          className="md:w-[100px] w-40"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <>
                  {Object.keys(currentMonthFav).length > 0 && (
                    <div className="flex justify-center flex-col items-center mt-[50px]">
                      <h1 className="text-white font-Bowlby text-[20px] md:text-[32px] md:leading-[52px] w-[90%] z-10">
                        Onze tips
                      </h1>
                      <div
                        style={{ ...bg3 }}
                        className="w-[90%] rounded-[20px] z-10 relative p-5"
                      >
                        {/* <h1 className="text-center font-Bowlby text-[20px] md:text-[32px] md:leading-[52px] text-white py-4">
                      "Tip van de maand"
                    </h1> */}
                        {currentMonthFav.length === 0 && (
                          <div className="flex items-center justify-center w-full py-4">
                            <h1 className="text-white font-Bowlby text-base md:text-[32px] md:leading-[52px]">
                              Geen festivals gevonden
                            </h1>
                          </div>
                        )}
                        {/* {currentMonthFav.map((f, index) => (
                          <div key={index} style={{ margin: "0px 0px" }}>
                            <FestivalCard
                              f={f}
                              line={index !== currentMonthFav.length - 1}
                            />
                          </div>
                        ))} */}
                        {Object.entries(currentMonthFav).map(
                          ([month, festivals]) => (
                            <div key={month}>
                              <div className="w-full flex flex-row items-center justify-center gap-2">
                                <h1 className="text-white font-Bowlby text-[20px] md:text-[32px] md:leading-[52px] w-fit">
                                  {month}
                                </h1>

                                <Divider
                                  style={{
                                    flexGrow: 1,
                                    margin: "0 10px",
                                    borderColor: "white",
                                    height: "5px",
                                    alignSelf: "center"
                                  }}
                                />
                              </div>

                              {festivals
                                ?.sort(
                                  (a, b) =>
                                    new Date(a.FromDate).getDate() -
                                    new Date(b.FromDate).getDate()
                                )
                                ?.map((f, index) => (
                                  <div
                                    key={index}
                                    style={{ margin: "0px 0px" }}
                                  >
                                    <FestivalCard f={f} line={true} />
                                  </div>
                                ))}
                            </div>
                          )
                        )}
                      </div>
                    </div>
                  )}

                  <div className="flex items-center mt-[50px] flex-col">
                    <h1 className="text-white font-Bowlby text-[20px] md:text-[32px] md:leading-[52px] w-[90%] z-10">
                      Festival Agenda
                    </h1>
                    <div
                      style={{ ...bg3 }}
                      className="w-[90%] p-[20px] rounded-[20px] z-10 relative"
                    >
                      {sortedMonths &&
                        (() => {
                          let totalFestivals = [];
                          let monthsProcessed = [];
                          const monthKeys = Object.keys(sortedMonths);

                          for (
                            let i = 0;
                            i < monthKeys.length && totalFestivals.length < 6;
                            i++
                          ) {
                            const month = monthKeys[i];
                            const festivals = sortedMonths[month].sort(
                              (a, b) => {
                                // First, compare by FromDate
                                const dateA = new Date(a.FromDate).getDate();
                                const dateB = new Date(b.FromDate).getDate();
                                if (dateA !== dateB) {
                                  return dateA - dateB;
                                }
                                // If FromDate is the same, sort by duration
                                const durationA = a.duration < 14 ? 0 : 1;
                                const durationB = b.duration < 14 ? 0 : 1;
                                return durationA - durationB;
                              }
                            );

                            if (!showAllFestivals) {
                              totalFestivals = [
                                ...totalFestivals,
                                ...festivals
                              ].slice(0, 6);
                              if (totalFestivals.length > 0) {
                                monthsProcessed.push(month);
                              }
                              if (totalFestivals.length === 6) break;
                            } else {
                              // When showAllFestivals is true, add all festivals from the first month and break
                              totalFestivals = [...festivals];
                              monthsProcessed.push(month);
                              break;
                            }
                          }

                          return monthsProcessed.map((month, monthIndex) => (
                            <div key={monthIndex}>
                              <div className="w-full flex flex-row items-center justify-center gap-2">
                                <h1 className="text-white font-Bowlby text-[20px] md:text-[32px] md:leading-[52px] w-fit">
                                  {month}
                                </h1>
                                <Divider
                                  style={{
                                    flexGrow: 1,
                                    margin: "0 10px",
                                    borderColor: "white",
                                    height: "5px",
                                    alignSelf: "center"
                                  }}
                                />
                              </div>
                              {totalFestivals
                                .filter((festival) =>
                                  sortedMonths[month].includes(festival)
                                )
                                .map((f, index) => (
                                  <div
                                    key={index}
                                    style={{ margin: "0px 0px" }}
                                  >
                                    <FestivalCard
                                      f={f}
                                      highlight={
                                        month === monthKeys[0] && index === 0
                                      }
                                      line={true}
                                    />
                                  </div>
                                ))}
                            </div>
                          ));
                        })()}

                      {Object.keys(sortedMonths).length === 0 && (
                        <div className="flex items-center justify-center w-full">
                          <h1 className="text-white font-Bowlby text-base md:text-[32px] md:leading-[52px]">
                            Geen festivals gevonden
                          </h1>
                        </div>
                      )}
                    </div>
                  </div>

                  {festivals.filter((festival) => festival.IsPromoted).length >
                    0 && (
                    <div className="flex justify-center flex-col items-center gap-4 mt-[50px]">
                      <div
                        style={{ ...bg3 }}
                        className="w-[90%] p-[20px] rounded-[20px] z-10 relative"
                      >
                        <h1 className="text-center font-Bowlby text-base md:text-[32px] md:leading-[52px] text-white py-4">
                          "Gepromoveerd festival"
                        </h1>
                        {festivals
                          .filter((festival) => festival.IsPromoted)
                          .map((f, index) => (
                            <div key={index} style={{ margin: "0px 0px" }}>
                              <FestivalCard f={f} line={false} />
                            </div>
                          ))}
                      </div>
                    </div>
                  )}
                  {showAllFestivals &&
                    sortedMonths &&
                    (() => {
                      const monthKeys = Object.keys(sortedMonths);
                      let festivalsToShow = [];

                      if (monthKeys.length > 0) {
                        for (let i = 1; i < monthKeys.length; i++) {
                          // Start from the second month
                          const month = monthKeys[i];
                          const festivals = sortedMonths[month].sort((a, b) => {
                            // First, compare by FromDate
                            const dateA = new Date(a.FromDate).getDate();
                            const dateB = new Date(b.FromDate).getDate();
                            if (dateA !== dateB) {
                              return dateA - dateB;
                            }
                            // If FromDate is the same, sort by duration
                            const durationA = a.duration < 14 ? 0 : 1;
                            const durationB = b.duration < 14 ? 0 : 1;
                            return durationA - durationB;
                          });

                          festivalsToShow.push({ month, festivals });
                        }
                      }

                      return festivalsToShow.length > 0 ? (
                        <div className="flex items-center mt-[50px] flex-col">
                          <div
                            style={{ ...bg3 }}
                            className="w-[90%] p-[20px] rounded-[20px] z-10 relative"
                          >
                            {festivalsToShow.map(
                              ({ month, festivals }, index) => (
                                <div key={index}>
                                  <div className="w-full flex flex-row">
                                    <h1 className="text-white font-Bowlby text-base md:text-[32px] md:leading-[52px] w-[15%]">
                                      {month}
                                    </h1>
                                    <Divider
                                      style={{
                                        width: "85%",
                                        margin: "0 10px",
                                        borderColor: "white",
                                        height: "5px",
                                        alignSelf: "center"
                                      }}
                                    />
                                  </div>
                                  {festivals.map((f, festivalIndex) => (
                                    <div
                                      key={festivalIndex}
                                      style={{ margin: "0px 0px" }}
                                    >
                                      <FestivalCard
                                        f={f}
                                        highlight={
                                          month === thirdMonth &&
                                          festivalIndex === 0
                                        }
                                        line={true}
                                      />
                                    </div>
                                  ))}
                                </div>
                              )
                            )}
                          </div>
                        </div>
                      ) : (
                        <div className="flex items-center justify-center w-full">
                          {/* <h1 className="text-white font-Bowlby text-base md:text-[32px] md:leading-[52px]">
                            Geen festivals gevonden
                          </h1> */}
                        </div>
                      );
                    })()}

                  {!showAllFestivals &&
                    Object.keys(sortedMonths).length !== 0 && (
                      <div className="flex items-start justify-center w-full md:mt-[25px] mb-[12px] mt-[22px]">
                        <div
                          className="relative overflow-hidden z-40  px-4 py-2 rounded-md text-white uppercase text-base font-bold cursor
                  -pointer transition-all duration-300 cursor-pointer bg-[#ec4899] group"
                          onClick={() => {
                            handleShowAllFestivals();
                            // scroll to top with scroll behaviour smooth
                            window.scrollTo({ top: 0, behavior: "smooth" });
                          }}
                        >
                          <button className="z-50 relative font-Poppins">
                            Check de hele Festival Agenda
                          </button>
                          <span className="absolute bottom-0 left-0 w-full h-0 group-hover:h-full bg-[#1474f4] rounded-md z-0 transition-all duration-300"></span>
                        </div>
                      </div>
                    )}

                  {/* <div className="flex items-center md:mt-[50px] mt-0 flex-col">
                    <h1 className="text-white font-Bowlby text-base md:text-[32px] md:leading-[52px] w-[90%] z-10">
                      Bij jou in de buurt
                    </h1>
                    <div
                      style={{ ...bg3 }}
                      className="w-[90%] px-[20px] rounded-[20px] z-10 relative"
                    >
                      {nearLoading && (
                        <div className="flex items-center justify-center w-full py-4">
                          <CircularProgress style={{ color: "white" }} />
                        </div>
                      )}
                      {!nearLoading &&
                        nearBy.length > 0 &&
                        nearBy.map((f, index) => (
                          <div key={index} style={{ margin: "20px 0px" }}>
                            <FestivalCard
                              f={f}
                              line={index !== nearBy.length - 1}
                            />
                          </div>
                        ))}
                      {!nearLoading && nearBy.length === 0 && (
                        <div className="flex items-center justify-center w-full py-4">
                          <h1 className="text-white font-Bowlby text-base md:text-[32px] md:leading-[52px]">
                            Geen festivals gevonden
                          </h1>
                        </div>
                      )}
                    </div>
                  </div> */}

                  <Footer />
                </>
              )}
            </div>
          </div>{" "}
        </>
      )}
    </>
  );
}
