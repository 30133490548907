import React, { useState, useEffect } from "react";
import logo from "../assets/logo.png";
import { SocialIcon } from "react-social-icons";
import SearchIcon from "@mui/icons-material/Search";
import { useNavigate } from "react-router-dom";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import { GetFestivals } from "../Client/request";

const Navbar = ({ setSearch }) => {
  const [navOpen, setNavOpen] = useState(false);
  const [searchText, setSearchText] = useState("");
  // const [artists, setArtists] = useState([]);
  const [festivals, setFestivals] = useState([]);
  const [searchArtists, setSearchArtists] = useState([]);
  const [searchFestivals, setSearchFestivals] = useState([]);
  const navigate = useNavigate();

  // const fetchArtists = async () => {
  //   await GetArtists().then((response) => {
  //     if (response) {
  //       setArtists(response.data);
  //     }
  //   });
  // };

  const fetchFestivals = async () => {
    const response = await GetFestivals();
    if (response) {
      setFestivals(response);
    }
  };

  useEffect(() => {
    // fetchArtists();
    fetchFestivals();
  }, []);

  const handleSearch = (text) => {
    // search for artists with names containing the search text and festivals with names containing the search text
    if (text === "") {
      setSearchArtists([]);
      setSearchFestivals([]);
      return;
    }
    // const artistResults =
    //   artists.length > 0 &&
    //   artists?.filter((artist) =>
    //     artist.Name.toLowerCase().includes(text.toLowerCase())
    //   );
    const festivalResults =
      festivals.length > 0 &&
      festivals?.filter((festival) =>
        festival.Name.toLowerCase().includes(text.toLowerCase())
      );
    // setSearchArtists(artistResults);
    setSearchFestivals(festivalResults);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      const searchContainer = document.getElementById("search-container");
      if (searchContainer && !searchContainer.contains(event.target)) {
        setSearchText("");
        setSearchArtists([]);
        setSearchFestivals([]);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [setSearchText, setSearchArtists, setSearchFestivals]);

  return (
    <>
      <div
        className={`${
          navOpen ? "w-screen " : "w-0"
        } fixed top-0 flex flex-col items-start justify-start transition-all  duration-300 z-50 gap-4 h-screen bg-[#58ccff]  overflow-hidden`}
      >
        <div className="flex flex-row items-center justify-between w-full p-8">
          <img
            src={logo}
            alt="logo"
            className="cursor-pointer w-[80px]"
            onClick={() => navigate("/")}
          />
          {/* button to close navbar */}
          <div className="cursor-pointer" onClick={() => setNavOpen(!navOpen)}>
            <KeyboardArrowLeftIcon className="text-4xl text-white" />
          </div>
        </div>
        <div className="flex flex-col items-start justify-start gap-2 text-xl px-8">
          <p
            className="cursor-pointer text-center text-white font-Poppins text-xl font-medium"
            onClick={() => {
              navigate("/");
              setNavOpen(false);
            }}
          >
            FESTIVAL AGENDA
          </p>

          {/* <p
            className="cursor-pointer text-center text-white font-Poppins text-xl font-medium"
            onClick={() => {
              navigate("/artists-overview");
              setNavOpen(false);
            }}
          >
            OVER ONS
          </p> */}

          <p
            className="cursor-pointer text-center text-white font-Poppins text-xl font-medium"
            onClick={() => {
              navigate("/contact");
              setNavOpen(false);
            }}
          >
            CONTACT
          </p>
          <p
            className="cursor-pointer text-center text-white font-Poppins text-xl font-medium"
            onClick={() => {
              navigate("/giveaways");
              setNavOpen(false);
            }}
          >
            WIN TICKETS
          </p>
          <div className="flex items-start -ml-3">
            <SocialIcon
              url="https://www.instagram.com/befesti.nl/"
              target="_blank"
              bgColor="transparent"
              fgColor="white"
              className="!h-[35px]"
            />
            <SocialIcon
              url="https://www.tiktok.com/@befesti.nl"
              target="_blank"
              bgColor="transparent"
              fgColor="white"
              className="!h-[35px]"
            />

            <SocialIcon
              url="https://open.spotify.com/show/0uXNuJhCNd2FyGFTQRFRyq?si=e3cae45f711f43fa"
              target="_blank"
              bgColor="transparent"
              fgColor="white"
              className="!h-[35px]"
            />
          </div>
        </div>
      </div>

      <div className="fixed top-0 left-0 w-full flex flex-col items-center justify-center z-50 bg-[#58CBFF] pb-4 ">
        <div
          className="bg-[#1474f4] py-1 flex flex-row items-center justify-center w-full cursor-pointer transition-all duration-300 group relative"
          onClick={() => window.open("https://skipjedip.nl/", "_blank")}
        >
          <p className="text-white md:text-base text-xs font-Poppins font-medium z-50 relative">
            Op zoek naar SKIP JE DIP!? Ga naar skipjedip.nl
          </p>
          <span className="absolute bottom-0 left-0 w-full h-0 group-hover:h-full bg-[#ec4899] z-0 transition-all duration-300"></span>
        </div>
        <div className="container md:w-[90%] w-[95%]  pt-[20px] flex items-center justify-between xl:flex-row md:flex-col flex-row text-white font-poppins text-[17px] font-medium leading-[17px] relative z-10 gap-2">
          <div className="flex items-center xl:justify-center md:gap-8 gap-2 xl:w-[43%] md:w-full w-full justify-between xl:px-0 md:px-3">
            <img
              src={logo}
              alt="logo"
              className="cursor-pointer h-[40px] "
              onClick={() => navigate("/")}
            />
            {/* make a search bar */}
            <div
              id="search-container"
              className="relative flex items-center justify-center gap-2 rounded-md bg-[#37a0d1] w-full py-1"
            >
              <input
                type="text"
                className="w-full bg-transparent text-base px-5 py-1 text-white focus:outline-0 placeholder:text-gray-200 font-Poppins"
                placeholder="Zoek festivals"
                value={searchText}
                onChange={(e) => {
                  setSearchText(e.target.value);
                  handleSearch(e.target.value);
                }}
              />
              <SearchIcon className="text-white h-[30px] cursor-pointer mr-2" />
              {searchText !== "" &&
                searchArtists.length === 0 &&
                searchFestivals.length === 0 && (
                  <div className="absolute top-[100%] left-0 w-full bg-white bg-opacity-70 z-40">
                    <p className="text-black text-lg text-center">
                      Geen resultaten gevonden
                    </p>
                  </div>
                )}
              {searchText !== "" &&
                (searchArtists.length > 0 || searchFestivals.length > 0) && (
                  <div className="absolute top-[100%] left-0 w-full bg-white bg-opacity-70 max-h-[500px] z-40 overflow-scroll no-scrollbar">
                    <div className="flex flex-col items-start justify-start">
                      {searchArtists.length > 0 && (
                        <p className="text-black text-lg font-Poppins px-2">
                          Artists
                        </p>
                      )}
                      {searchArtists?.map((artist, index) => (
                        <p
                          key={index}
                          className="text-gray-500 font-Poppins hover:text-black text-base cursor-pointer w-full hover:bg-white px-2"
                          onClick={() => {
                            navigate(`/artists-details/${artist.ArtistID}`);
                            setSearchText("");
                          }}
                        >
                          {artist.Name}
                        </p>
                      ))}
                      {searchFestivals.length > 0 && (
                        <p className="text-black text-lg px-2 font-Poppins">
                          Festivals
                        </p>
                      )}
                      {searchFestivals.map((festival, index) => (
                        <p
                          key={index}
                          className="text-gray-500 font-Poppins hover:text-black text-base cursor-pointer w-full hover:bg-white px-2"
                          onClick={() => {
                            navigate(
                              `/festival-details/${festival.Name.replace(
                                / /g,
                                "_"
                              )}`
                            );
                            setSearchText("");
                          }}
                        >
                          {festival.Name}
                        </p>
                      ))}
                    </div>
                  </div>
                )}
            </div>
          </div>
          <div className="flex items-center xl:justify-center justify-between gap-4 col-12 xl:col-6 xl:w-fit md:w-full w-fit xl:mt-0 mt-2 xl:px-0 md:px-3">
            <div className="w-full flex items-center md:justify-start xl:justify-center justify-between gap-4">
              <div
                className="relative overflow-hidden z-50 hidden md:inline-block px-4 py-2 rounded-full text-white uppercase text-xs cursor-pointer transition-all duration-300 bg-[#ec4899] group"
                onClick={() => navigate("/")}
              >
                <p className="z-50 relative font-Bowlby font-medium select-none">
                  FESTIVAL AGENDA
                </p>
                <span className="absolute bottom-0 left-0 w-full h-0 group-hover:h-full bg-[#1474f4] rounded-full z-0 transition-all duration-300"></span>
              </div>
              <div
                className="cursor-pointer md:hidden  mr-18"
                onClick={() => setNavOpen(!navOpen)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-8 w-8 text-white"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  style={{ transform: "rotate(90deg)" }}
                >
                  <path
                    fillRule="evenodd"
                    className="text-white"
                    d="M3 4.75a.75.75 0 011.5 0v10.5a.75.75 0 01-1.5 0V4.75zm5.25 0a.75.75 0 011.5 0v10.5a.75.75 0 01-1.5 0V4.75zm5.25 0a.75.75 0 011.5 0v10.5a.75.75 0 01-1.5 0V4.75z"
                    clipRule="evenodd"
                  />
                </svg>
              </div>
              {/* <div
                className="relative overflow-hidden z-50 hidden md:inline-block px-4 py-2 rounded-full text-white uppercase text-xs cursor-pointer transition-all duration-300 bg-[#ec4899] group"
                onClick={() => navigate("/artists-overview")}
              >
                <p className="z-50 relative font-Bowlby font-medium select-none">
                  OVER ONS
                </p>
                <span className="absolute bottom-0 left-0 w-full h-0 group-hover:h-full bg-[#1474f4] rounded-full z-0 transition-all duration-300"></span>
              </div> */}
              <div
                className="relative overflow-hidden z-50 hidden md:inline-block px-4 py-2 rounded-full text-white uppercase text-xs cursor-pointer transition-all duration-300 bg-[#ec4899] group"
                onClick={() => navigate("/contact")}
              >
                <p className="z-50 relative font-Bowlby font-medium select-none">
                  CONTACT
                </p>
                <span className="absolute bottom-0 left-0 w-full h-0 group-hover:h-full bg-[#1474f4] rounded-full z-0 transition-all duration-300"></span>
              </div>
              <div
                className="relative overflow-hidden z-50 hidden md:inline-block px-4 py-2 rounded-full text-white uppercase text-xs cursor-pointer transition-all duration-300 bg-[#1474f4] group"
                onClick={() => navigate("/giveaways")}
              >
                <p className="z-50 relative font-Bowlby font-medium select-none">
                  WIN TICKETS
                </p>
                <span className="absolute bottom-0 left-0 w-full h-0 group-hover:h-full bg-[#ec4899] rounded-full z-0 transition-all duration-300"></span>
              </div>
            </div>
            <div className="hidden md:flex items-center">
              <SocialIcon
                url="https://www.instagram.com/befesti.nl/"
                target="_blank"
                bgColor="transparent"
                fgColor="white"
                className="!h-[35px]"
              />
              <SocialIcon
                url="https://www.tiktok.com/@befesti.nl"
                target="_blank"
                bgColor="transparent"
                fgColor="white"
                className="!h-[35px]"
              />

              <SocialIcon
                url="https://open.spotify.com/show/0uXNuJhCNd2FyGFTQRFRyq?si=e3cae45f711f43fa"
                target="_blank"
                bgColor="transparent"
                fgColor="white"
                className="!h-[35px]"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Navbar;
