/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { months } from "../../constants/Form_constants";
import WatchLaterIcon from "@mui/icons-material/WatchLater";
import LocationOnIcon from "@mui/icons-material/LocationOn";
// import { Button, styled } from "@mui/material";
import { Morebutton } from "../../styles/home";
import TicketSwap from "../../assets/TicketSwap.png";
import festImg from "../../assets/festival.png";
import { MdWorkspacePremium } from "react-icons/md";
import { genreColors } from "../../constants/Genre";

// const chip = {
//   textAlign: "center",
//   borderRadius: "8px",
//   backgroundColor: "white",
//   padding: "0px 10px",
//   height: "31px",
//   fontFamily: "Poppins",
//   fontWeight: 700,
//   lineHeight: "36px",
//   display: "flex",
//   alignItems: "center"
// };

export default function FestivalCard({ f, line }) {
  const [date, setDate] = React.useState(
    // if f.FromDate is not a date object then create a new date object
    f.FromDate instanceof Date ? f.FromDate : new Date(f.FromDate)
  );
  const [date2, setDate2] = React.useState(new Date(f.EndDate));
  const [showEndDate, setShowEndDate] = useState(false);

  const navigate = useNavigate();

  // useEffect(() => {
  //   console.log("prop",f)
  //   // let prop = f.f;
  //   // console.log("p2",prop)
  //   // if(f){
  //   //   setDate(new Date(f.Time))
  //   // }
  // }, [])

  useEffect(() => {
    const duration = new Date(f.EndDate) - new Date(f.FromDate);
    const durationInHours = duration / (1000 * 60 * 60);
    setShowEndDate(durationInHours > 14);
  }, [f]);

  return (
    <>
      <div
        className={`flex rounded-md flex-row md:gap-0 gap-4 w-full h-full justify-between py-3 px-2`}
        style={{
          backgroundColor: f.Color || "#1A1A1A"
        }}
        onClick={() =>
          window.innerWidth < 768 &&
          window.open(
            `/festival-details/${f.Name.replace(/ /g, "_")}`,
            "_blank"
          )
        }
      >
        <div className="flex flex-row items-center justify-start md:gap-0 h-full w-full">
          <div className="md:pl-3 md:pr-2 pl-2 pr-1 m-0 flex flex-col items-start justify-center h-full cursor-pointer">
            <p
              className={`font-Bowlby p-0 m-0 text-[18px] text-white text-center w-full`}
            >
              {f.FromDate.getDate()}

              {f.EndDate &&
                new Date(f.EndDate).getDate() !== f.FromDate.getDate() &&
                showEndDate && (
                  <span>
                    {/* <p style={{fontSize:'2px'}}>{" - "}</p> */}
                    <span className="flex flex-row justify-center">
                      <hr
                        style={{
                          height: "3px",
                          width: "10px",
                          background: "white"
                        }}
                      ></hr>
                    </span>
                    <p>{new Date(f.EndDate).getDate()}</p>
                  </span>
                )}
            </p>
            <p
              className={`font-Poppins font-bold p-0 m-0 text-[14px] text-white -mt-2 text-center w-full`}
            >
              {f?.Month?.slice(0, 3).toUpperCase() || "JAN"}
            </p>
          </div>

          <div className="md:w-[70%] w-[91%] md:ml-2 ml-1 flex flex-col">
            <span
              className={`font-Bowlby md:text-[18px] text-[14px] text-white`}
              style={{ lineHeight: "1" }}
            >
              {f.Name}
              <br />
            </span>
            <div
              className={`flex font-Poppins md:text-xs text-[10px] items-center font-bold text-white`}
              style={{ margin: "2px 0px" }}
            >
              <span>
                {f.Location}, {f.City}
              </span>
            </div>
            <div className="w-full flex items-center md:justify-start justify-between gap-1">
              <div className="flex items-center gap-1 md:w-full w-[70%] flex-wrap">
                {f &&
                  f?.Genre?.map((genre, index) => (
                    <div
                      key={index}
                      className={`text-black md:text-[10px] text-[7px] font-Poppins font-bold py-[2px] px-[4px] rounded-md flex flex-row items-center justify-center gap-1 `}
                      style={{
                        backgroundColor: genreColors.find(
                          (g) => g.genre === genre.toLowerCase()
                        ).color
                      }}
                    >
                      <span>{genre}</span>
                    </div>
                  ))}

                <p className="font-Bowlby text-white p-0 m-0  text-[12px]">
                  {f.Price && f.Price[0] !== "€" && <span> €</span>}
                  {f.Price}
                </p>
              </div>
              <div className=" flex items-center gap-1 md:hidden md:mr-6 mr-2">
                {/* <p className="font-Bowlby text-white p-0 m-0  text-[12px]">
                  {f.Price && f.Price[0] !== "€" && <span> €</span>}
                  {f.Price}
                </p> */}
                <img
                  src={TicketSwap}
                  alt="ticketswap"
                  className="md:w-[26px] w-5 cursor-pointer"
                  onClick={() => {
                    window.open(
                      f.TicketsURL.includes("https://www.")
                        ? f.TicketsURL
                        : `https://www.${f.TicketsURL}`,
                      "_blank"
                    );
                    console.log(f.TicketsURL);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="hidden w-[23%] md:flex flex-row items-center justify-center gap-4">
          <div className=" w-auto pr-0 md:pl-[30px] pl-[10px] flex md:flex-col flex-row items-center justify-center gap-2">
            <img
              src={TicketSwap}
              alt="ticketswap"
              className="md:w-[26px] w-5 cursor-pointer"
              onClick={() => {
                window.open(
                  f.TicketsURL.includes("https://www.")
                    ? f.TicketsURL
                    : `https://www.${f.TicketsURL}`,
                  "_blank"
                );
                console.log(f.TicketsURL);
              }}
            />
          </div>
          {f.FestivalID && (
            <div className="flex items-center justify-center">
              <Morebutton id={f.Name}>Meer info</Morebutton>
            </div>
          )}
        </div>
      </div>
      {line && (
        <div className="w-full flex items-center justify-center my-2">
          <div className="w-[97%] h-[1px] bg-white bg-opacity-60" />
        </div>
      )}
    </>
  );
}
