import axios from "axios";

const API_URL = "https://backend.befesti.nl";

axios.defaults.withCredentials = true;

export async function GetArtists() {
  const config = {
    method: "get",
    url: API_URL + "/artists/"
  };

  return axios(config)
    .then((response) => {
      if (response.data.responseCode === 200) {
        return { data: response.data.response };
      } else if (response.data.responseCode === 300) {
        return { data: [] };
      } else {
        return { error: "Some error occurred!:" + response.data.responseCode };
      }
    })
    .catch((error) => {
      console.log(error);
      return { error: "Some error occurred!:" + error };
    });
}

export async function GetFestivals() {
  const config = {
    method: "get",
    url: API_URL + "/festivals/"
  };

  return axios(config)
    .then((response) => {
      if (response.data.responseCode === 200) {
        return response.data.response;
      } else {
        return { error: "Some error occurred!:" + response.data.responseCode };
      }
    })
    .catch((error) => {
      console.log(error);

      return { error: "Some error occurred!:" + error };
    });
}

export async function GetArtistDetails(id) {
  const config = {
    method: "get",
    url: API_URL + "/artists/artist-by-id?artist_Id=" + id
  };

  return axios(config)
    .then((response) => {
      if (response.data.responseCode === 200) {
        return response.data.response;
      } else {
        return { error: "Some error occurred!:" + response.data.responseCode };
      }
    })
    .catch((error) => {
      console.log(error);

      return { error: "Some error occurred!:" + error };
    });
}

export async function GetFestivalDetails(id) {
  const config = {
    method: "get",
    url: API_URL + "/festivals/festival-by-name?festival_Name=" + id
  };

  return axios(config)
    .then((response) => {
      if (response.data.responseCode === 200) {
        return response.data.response;
      } else {
        return { error: "Some error occurred!:" + response.data.responseCode };
      }
    })
    .catch((error) => {
      console.log(error);

      return { error: "Some error occurred!:" + error };
    });
}

export async function GetArtistFestivals(id) {
  const config = {
    method: "get",
    url: API_URL + "/artistsfestivals/festivals-of-artist-by-id?artist_Id=" + id
  };

  return axios(config)
    .then((response) => {
      if (response.data.responseCode === 200) {
        return response.data.response;
      } else if (response.data.responseCode === 300) {
        return null;
      } else {
        return { error: "Some error occurred!:" + response.data.responseCode };
      }
    })
    .catch((error) => {
      console.log(error);

      return { error: "Some error occurred!:" + error };
    });
}

export async function GetFestivalArtists(id) {
  const config = {
    method: "get",
    url:
      API_URL + "/artistsfestivals/artists-of-festival-by-id?festival_Id=" + id
  };

  return axios(config)
    .then((response) => {
      if (response.data.responseCode === 200) {
        return response.data.response;
      } else if (response.data.responseCode === 300) {
        return null;
      } else {
        return { error: "Some error occurred!:" + response.data.responseCode };
      }
    })
    .catch((error) => {
      console.log(error);

      return { error: "Some error occurred!:" + error };
    });
}

export async function GetAllTickets() {
  const config = {
    method: "get",
    url: API_URL + "/tickets/"
  };

  return axios(config)
    .then((response) => {
      if (response.data.responseCode === 200) {
        return response.data.response;
      } else if (response.data.responseCode === 300) {
        return null;
      } else {
        return { error: "Some error occurred!:" + response.data.responseCode };
      }
    })
    .catch((error) => {
      console.log(error);

      return { error: "Some error occurred!:" + error };
    });
}

export async function AddContact(data) {
  await axios
    .post(API_URL + "/contacts/add", data)
    .then((response) => {
      if (response.data.responseCode === 200) {
        return response.data.response;
      } else {
        return { error: "Some error occurred!:" + response.data.responseCode };
      }
    })
    .catch((error) => {
      console.log(error);

      return { error: "Some error occurred!:" + error };
    });
}

export async function AddEvent(data) {
  delete data.fromtime;
  delete data.fromdate;
  delete data.endtime;
  delete data.enddate;
  delete data.instagram_url;
  delete data.additional_info;

  if (data.tickets_url === "") {
    delete data.tickets_url;
  }

  if (data.festival_description === "") {
    delete data.festival_description;
  }

  const response = await axios.post(API_URL + "/festivals/add-by-user", data);
  if (response.data.responseCode === 200) {
    return { msg: "Festival added successfully!" };
  } else {
    return { error: response.data.message };
  }
}

export async function AddSubscriber(data) {
  await axios
    .post(API_URL + "/email/add", data)
    .then((response) => {
      if (response.data.responseCode === 200) {
        return response.data.response;
      } else {
        return { error: "Some error occurred!:" + response.data.responseCode };
      }
    })
    .catch((error) => {
      console.log(error);

      return { error: "Some error occurred!:" + error };
    });
}

export async function AddFestivalArtists(data) {
  await axios
    .post(API_URL + "/artistsfestivals/add", data)
    .then((response) => {
      if (response.data.responseCode === 200) {
        return response.data.response;
      } else {
        return { error: "Some error occurred!:" + response.data.responseCode };
      }
    })
    .catch((error) => {
      console.log(error);

      return { error: "Some error occurred!:" + error };
    });
}
