import React, { useState } from "react";
import { SocialIcon } from "react-social-icons";
import { useNavigate } from "react-router-dom";
import { AddSubscriber } from "../Client/request";
import logo from "../assets/logo.png";
import { BiSolidSend } from "react-icons/bi";

export default function Footer() {
  const navigate = useNavigate();
  const [Email, setEmail] = useState("");

  const handleSubmit = async (e) => {
    if (Email === "") {
      return;
    }
    e.preventDefault();
    const data = {
      email: Email,
      genre: "All"
    };
    const response = await AddSubscriber(data);
    setEmail("");
    console.log(response);
  };

  return (
    <div className="mt-10 w-full bg-cover bg-no-repeat bg-center flex flex-col items-center justify-center">
      <div className="my-10 md:my-20 z-20 top-0 md:top-[180px] left-0 w-full">
        <h1 className="text-white text-3xl md:text-[64px] md:leading-[76px] font-Bowlby text-center">
          Het all-in-one <br /> festivalplatform.
        </h1>
        <div className="flex flex-row flex-wrap justify-center items-center mt-10 md:gap-16 gap-8">
          <div className="flex flex-col justify-center items-center gap-2 font-Poppins">
            <img src={logo} alt="logo" className="h-20" />
            <p className="text-white text-lg font-semibold">© 2024 beFesti</p>

            <div className="flex flex-row items-center justify-center gap-2">
              <SocialIcon
                url="https://www.instagram.com/befesti.nl/"
                target="_blank"
                bgColor="transparent"
                fgColor="white"
                className="!h-[35px]"
              />
              <SocialIcon
                url="https://www.tiktok.com/@befesti.nl"
                target="_blank"
                bgColor="transparent"
                fgColor="white"
                className="!h-[35px]"
              />

              <SocialIcon
                url="https://open.spotify.com/show/0uXNuJhCNd2FyGFTQRFRyq?si=e3cae45f711f43fa"
                target="_blank"
                bgColor="transparent"
                fgColor="white"
                className="!h-[35px]"
              />
            </div>
          </div>
          <div className="hidden md:flex flex-col font-Poppins md:items-start items-center justify-center gap-2 ">
            <h1 className="text-white text-lg font-Bowlby text-center">
              Win gratis festivaltickets
            </h1>
            <h1 className="text-white text-sm font-medium font-Poppins md:text-left text-center max-w-[300px]">
              Maak kans op gratis festivaltickets door je nu aan te melden voor
              de Festival Agenda nieuwsbrief.
            </h1>
            <div className="flex flex-row items-center justify-center gap-4 max-w-[300px]">
              <input
                type="email"
                placeholder="Email"
                value={Email}
                onChange={(e) => setEmail(e.target.value)}
                className="bg-transparent border-2 border-white text-white px-4 py-2 font-semibold text-lg focus:outline-none placeholder:text-white rounded-lg w-full"
              />
              <div className="relative overflow-hidden z-50  px-4 py-2 rounded-md text-white uppercase text-base font-bold cursor-pointer transition-all duration-300 bg-[#1474f4] group h-full">
                <button className="z-50 relative" onClick={handleSubmit}>
                  <BiSolidSend />
                </button>
                <span className="absolute bottom-0 left-0 w-full h-0 group-hover:h-full bg-[#ec4899] rounded-md z-0 transition-all duration-300"></span>
              </div>
            </div>
          </div>
          <div className="text-right text-white font-medium text-lg gap-2 font-Poppins flex flex-col items-start justify-center">
            <p className="cursor-pointer" onClick={() => navigate("/")}>
              Festival Agenda
            </p>

            <p
              onClick={() => navigate("/add_event")}
              className="cursor-pointer"
            >
              Event toevoegen
            </p>
            <p
              onClick={() => navigate("/promote_event")}
              className="cursor-pointer"
            >
              Event promoten
            </p>
            {/* <p className="cursor-pointer" onClick={() => navigate("/over_ons")}>
              Over ons
            </p> */}
            <p className="cursor-pointer" onClick={() => navigate("/contact")}>
              Contact
            </p>
          </div>
          <div className="md:hidden flex flex-col items-center justify-center gap-2 ">
            <h1 className="text-white text-lg font-Bowlby text-center">
              Win gratis festivaltickets
            </h1>
            <h1 className="text-white text-sm font-medium font-Poppins text-center max-w-[300px]">
              Maak kans op gratis festivaltickets door je nu aan te melden voor
              de Festival Agenda nieuwsbrief.
            </h1>
            <div className="flex flex-row items-center justify-center gap-4 max-w-[300px]">
              <input
                type="email"
                placeholder="Email"
                value={Email}
                onChange={(e) => setEmail(e.target.value)}
                className="bg-transparent border-2 border-white text-white px-4 py-2 font-semibold text-lg focus:outline-none placeholder:text-white rounded-lg w-full"
              />
              <div className="relative overflow-hidden z-50  px-4 py-2 rounded-md text-white uppercase text-base font-bold cursor-pointer transition-all duration-300 bg-[#1474f4] group h-full">
                <button className="z-50 relative" onClick={handleSubmit}>
                  <BiSolidSend />
                </button>
                <span className="absolute bottom-0 left-0 w-full h-0 group-hover:h-full bg-[#ec4899] rounded-md z-0 transition-all duration-300"></span>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <img src={Main} alt="main bg" className="relative z-5 w-full" /> */}
    </div>
  );
}
