import React, { useState, useEffect } from "react";
import HomeLayout from "../../layouts/HomeLayout";
import { useNavigate } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { AddContact } from "../../Client/request";

const Contact = () => {
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    // after 2 seconds set pageLoading to false
    setTimeout(() => {
      setLoading(false);
    }, 400);
  }, []);

  return (
    <>
      {loading ? (
        <div className="flex items-center justify-center h-screen w-screen bg-[#5ACCFF]">
          <div className="flex items-center justify-center flex-col">
            <h1 className="text-white font-Bowlby text-2xl md:text-5xl">
              Contact
            </h1>
            <div className="flex items-center justify-center">
              <div className="animate-spin rounded-full h-20 md:h-32 w-20 md:w-32 border-t-2 border-[#fff]"></div>
            </div>
          </div>
        </div>
      ) : (
        <HomeLayout>
          <div className="relative top-0 md:mt-40 mt-28 md:mb-10 mb-6 w-full">
            <h1 className="text-5xl md:text-[90px] font-Bowlby leading-[52px] md:leading-[99px] text-center text-white relative z-10">
              Contact
            </h1>
          </div>
          <div className="flex items-center justify-center mt-[50px] flex-col gap-4 w-full z-20 -mb-20 md:px-0 px-2">
            <p className="font-Poppins text-xl text-white md:max-w-[70%] w-full z-20 font-medium text-center">
              Voor al jouw vragen en opmerkingen kun jij ons bereiken door een
              mail te sturen naar{" "}
              <a href="mailto:info@befesti.nl" className="text-[#1474f4]">
                info@befesti.nl
              </a>{" "}
              of door het onderstaande contactformulier in te vullen.
            </p>

            <p
              className="font-Poppins text-2xl text-white md:max-w-[70%] w-full z-20 font-bold text-center cursor-pointer"
              onClick={() => {
                navigate("/add_event");
              }}
            >
              Wil je een festival/event toevoegen?
            </p>
            <div className="flex flex-col items-start justify-center md:max-w-[70%] w-full z-20 gap-4 my-10 md:px-0 px-2 ">
              <ContactForm />
            </div>
          </div>
        </HomeLayout>
      )}
    </>
  );
};

const ContactForm = () => {
  const initialValues = {
    name: "",
    email: "",
    message: ""
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Naam is verplicht"),
    email: Yup.string().email("Ongeldige email").required("Email is verplicht"),
    message: Yup.string().required("Bericht is verplicht")
  });

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    const response = await AddContact(values);
    resetForm();
    console.log(response);
    setSubmitting(false);
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting }) => (
        <Form className="w-full flex flex-col items-start justify-center gap-2">
          <div className="flex md:flex-row flex-col items-center justify-between w-full gap-2">
            <div className="flex flex-col items-start justify-center w-full gap-2">
              <Field
                type="text"
                name="name"
                placeholder="Naam"
                className="focus:outline-0 w-[100%] h-[50px] rounded-lg p-4 bg-transparent border-2 border-white placeholder:text-white text-white font-Poppins text-xl"
              />
              <ErrorMessage
                name="name"
                component="div"
                className="text-red-500 mb-2"
              />
            </div>
            <div className="flex flex-col items-start justify-center w-full gap-2">
              <Field
                type="email"
                name="email"
                placeholder="Email"
                className="focus:outline-0 w-[100%] h-[50px] rounded-lg p-4 bg-transparent border-2 border-white placeholder:text-white text-white font-Poppins text-xl"
              />
              <ErrorMessage
                name="email"
                component="div"
                className="text-red-500 mb-2"
              />
            </div>
          </div>
          <Field
            as="textarea"
            name="message"
            placeholder="Bericht"
            rows={4}
            className="focus:outline-0 w-[100%] rounded-lg p-4 bg-transparent border-2 border-white placeholder:text-white text-white font-Poppins text-xl resize-none"
          />
          <ErrorMessage
            name="message"
            component="div"
            className="text-red-500 mb-2"
          />
          <button
            type="submit"
            disabled={isSubmitting}
            className="relative overflow-hidden z-50 px-4 py-2 rounded-md text-white uppercase text-base font-bold cursor-pointer transition-all duration-300 bg-[#ec4899] group"
          >
            <button className="z-50 relative font-Poppins">Versturen</button>
            <span className="absolute bottom-0 left-0 w-full h-0 group-hover:h-full bg-[#1474f4] rounded-md z-0 transition-all duration-300"></span>
          </button>
        </Form>
      )}
    </Formik>
  );
};

export default Contact;
