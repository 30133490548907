import React from "react";
import { genreColors } from "../constants/Genre";

const TicketCard = ({ f, line }) => {
  return (
    <>
      <div
        className={`flex rounded-md flex-row md:gap-0 gap-4 w-full h-full justify-between p-2`}
        style={{
          backgroundColor: f.BgColor || "#1A1A1A"
        }}
        onClick={() =>
          window.innerWidth < 768 && window.open(f.GoogleForm, "_blank")
        }
      >
        <div className="flex flex-row items-center justify-start md:gap-0 h-full w-full">
          <div className="md:pl-3 md:pr-2 pl-2 pr-1 m-0 flex flex-col items-center justify-center h-full">
            <p className={`font-Bowlby p-0 m-0 text-[18px] text-white`}>
              {new Date(f.FromDate).getDate()}
            </p>
            <p
              className={`font-Poppins font-bold p-0 m-0 text-[14px] text-white -mt-2`}
            >
              {f?.Month?.slice(0, 3).toUpperCase() || "JAN"}
            </p>
          </div>

          <div className="md:w-[70%] w-[91%] md:ml-2 ml-1">
            <span className={`font-Bowlby md:text-[18px] text-xs text-white`}>
              {f.Title}
              <br />
            </span>
            <div
              className={`flex font-Poppins md:text-xs text-[8px] items-center font-bold text-white -mt-1`}
            >
              <span>
                {f.Location}, {f.City}
              </span>
            </div>
            <div className="w-full flex items-center md:justify-start justify-between gap-1">
              <div className="flex items-center gap-1 md:w-full w-[70%] flex-wrap">
                {f &&
                  f?.Genre?.map((genre, index) => (
                    <div
                      key={index}
                      className={`text-black text-[10px] font-Poppins font-bold py-[2px] px-[4px] rounded-md flex flex-row items-center justify-center gap-1 `}
                      style={{
                        backgroundColor: genreColors.find(
                          (g) => g.genre === genre.toLowerCase()
                        ).color
                      }}
                    >
                      <span>{genre}</span>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
        <div className="hidden w-[23%] md:flex flex-row items-center justify-center gap-4">
          <div className=" w-auto pr-0 md:pl-[30px] pl-[10px] flex md:flex-col flex-row items-center justify-center gap-2"></div>
          <div className="md:flex hidden items-center justify-center">
            <div
              className="relative overflow-hidden z-40  px-4 py-2 rounded-full text-white md:text-sm text-xs font-bold cursor-pointer transition-all duration-300 bg-[#ec4899] group border-2 border-white"
              onClick={() => {
                window.open(f.GoogleForm, "_blank");
              }}
            >
              <button className="z-50 relative font-Poppins">Doe mee</button>
              <span className="absolute bottom-0 left-0 w-full h-0 group-hover:h-full bg-[#1474f4] rounded-md z-0 transition-all duration-300"></span>
            </div>
          </div>
        </div>
      </div>
      {line && (
        <div className="w-full flex items-center justify-center my-2">
          <div className="w-[97%] h-[1px] bg-white bg-opacity-60" />
        </div>
      )}
    </>
  );
};

export default TicketCard;
