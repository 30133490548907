/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import { Button, Divider, Menu, MenuItem, styled } from "@mui/material";
import React, { useEffect } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import {
  Genre,
  Location,
  Price,
  // age,
  months,
  festivals
} from "../constants/Form_constants";
import { IoFunnelOutline } from "react-icons/io5";
import { FaCheck } from "react-icons/fa";
import { useSelector } from "react-redux";

const ListButton = styled(Button)({
  boxShadow: [],
  fontSize: "20px",
  lineHeight: "28px",
  backgroundColor: "#ffffff",
  fontfamily: "Poppins",
  textTransform: [],
  fontWeight: 500,
  color: "black",
  borderRadius: "10px",
  width: "144px",
  padding: "10px",
  height: "52px",
  "&:hover": {
    backgroundColor: "#cacaca"
  },
  "@media (max-width: 1024px)": {
    backgroundColor: "transparent",
    color: "white",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    "&:hover": {
      backgroundColor: "transparent"
    }
  }
});

const FilterButton = styled(Button)({
  boxShadow: [],
  backgroundColor: "#ff6285",
  color: "white",
  borderRadius: "8.4px",
  width: "40px",
  height: "40px",
  "&:hover": {
    backgroundColor: "red"
  },
  "@media (max-width: 768px)": {
    color: "white",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "5px",
    // width: "90%",
    "&:hover": {
      backgroundColor: "transparent"
    }
  }
});

const HomeForm = (FestivalsObject) => {
  const [anchorEl_month, setAnchorEl_month] = React.useState(null);
  const open_month = Boolean(anchorEl_month);
  const [anchorEl_loc, setAnchorEl_loc] = React.useState(null);
  const open_loc = Boolean(anchorEl_loc);
  // const [anchorEl_age, setAnchorEl_age] = React.useState(null);
  // const open_age = Boolean(anchorEl_age);
  const [anchorEl_gen, setAnchorEl_gen] = React.useState(null);
  const open_gen = Boolean(anchorEl_gen);
  const [anchorEl_price, setAnchorEl_price] = React.useState(null);
  const open_price = Boolean(anchorEl_price);
  const [anchorEl_festival, setAnchorEl_festival] = React.useState(null);
  const open_festival = Boolean(anchorEl_festival);
  const [payload, setPayload] = React.useState({
    month: [],
    location: [],
    genre: [],
    age: [],
    price: [],
    festival: ["Festivals"]
  });
  const [mobFilter, setMobFilter] = React.useState(false);

  const month = useSelector((state) => state.month.month);

  // const handleClick = (func, val) => {
  //   func(null);
  //   setPayload((prevPayload) => ({
  //     ...prevPayload,
  //     ...val
  //   }));
  // };

  const toggleSelection = (category, value) => {
    setPayload((prevPayload) => ({
      ...prevPayload,
      [category]: prevPayload[category].includes(value)
        ? prevPayload[category].filter((item) => item !== value)
        : [...prevPayload[category], value]
    }));
  };

  useEffect(() => {
    if (month !== "") {
      setPayload((prevPayload) => ({
        ...prevPayload,
        month: [month?.replace(month[0], month[0].toUpperCase())]
      }));
      Object.keys(FestivalsObject.payload).length !== 0 &&
        handleFilterButtonClick();
    } else {
      setPayload((prevPayload) => ({
        ...prevPayload,
        month: []
      }));
      Object.keys(FestivalsObject.payload).length !== 0 &&
        handleFilterButtonClick();
    }
  }, [month]);

  useEffect(() => {
    // close any open menus if FestivalsObject.popUp is true
    if (FestivalsObject.popUp) {
      setAnchorEl_month(null);
      setAnchorEl_loc(null);
      setAnchorEl_gen(null);
      setAnchorEl_price(null);
      setAnchorEl_festival(null);
    }
  }, [FestivalsObject.popUp]);

  const handleFilterButtonClick = () => {
    // Call the filtering logic here
    const filteredFestivals = FestivalsObject?.payload?.filter((festival) => {
      if (
        payload.genre.length === 0 &&
        payload.location.length === 0 &&
        payload.age.length === 0 &&
        payload.price.length === 0 &&
        payload.month.length === 0 &&
        payload.festival.length === 0
      ) {
        FestivalsObject.setFilterApplied(false);
      } else {
        FestivalsObject.setFilterApplied(true);
      }
      if (!festival.IsPromoted) {
        if (payload.location.includes("Noord-Holland")) {
          if (!payload.location.includes("Amsterdam")) {
            payload.location.push("Amsterdam");
          }
        }
        if (payload.location.includes("Zuid-Holland")) {
          if (!payload.location.includes("Rotterdam")) {
            payload.location.push("Rotterdam");
          }
        }
        return (
          (payload.genre === null ||
            payload.genre.length === 0 ||
            payload.genre.some((genre) =>
              festival.Genre.includes(genre.toUpperCase())
            )) &&
          (payload.location === null ||
            payload.location.length === 0 ||
            payload.location.includes(festival.Locatie)) &&
          (payload.age === null ||
            payload.age.length === 0 ||
            payload.age.includes(festival.AgeCategory)) &&
          (payload.price === null ||
            payload.price.length === 0 ||
            payload.price.includes(festival.PriceCategory)) &&
          (payload.month === null ||
            payload.month.length === 0 ||
            payload.month.includes(festival.Month)) &&
          (payload.festival === null ||
            payload.festival.length === 0 ||
            payload.festival.includes(festival.Festival))
        );
      }
    });

    setMobFilter(false);

    // Pass the filtered festivals back to the parent component
    FestivalsObject.onChangeFilters(filteredFestivals);
  };

  // useEffect(() => {
  //   Object.keys(FestivalsObject.payload).length !== 0 &&
  //     document.getElementById("filterButton").click();
  // }, [FestivalsObject.payload]);

  return (
    <>
      <div
        className={`${
          mobFilter ? "w-screen" : "w-0"
        } bg-[#5accff] overflow-hidden h-screen z-50 fixed top-0 transition-all duration-300`}
      >
        <div className="flex justify-between items-center p-4">
          <h1 className="font-Poppins text-4xl font-bold text-white">Filter</h1>
          <div
            className="flex justify-center items-center rounded-[15px]  w-[40px] h-[40px]"
            onClick={() => setMobFilter(false)}
          >
            <KeyboardArrowLeftIcon className="text-4xl text-white" />
          </div>
        </div>
        <div className="flex flex-col justify-start  items-start p-4">
          <ListButton
            id="month"
            aria-haspopup="true"
            onClick={(e) => setAnchorEl_month(e.currentTarget)}
          >
            <div className="flex items-center justify-between ">
              <span
                style={{
                  color: payload.month.length > 0 ? "#ec4899" : "white"
                }}
                className="normal-case"
              >
                {payload.month && payload.month.length === 0
                  ? "Maand"
                  : "Maand"}
              </span>
              <KeyboardArrowDownIcon />
            </div>
          </ListButton>
          <Menu
            anchorEl={anchorEl_month}
            open={open_month}
            onClose={() => setAnchorEl_month(null)}
            PaperProps={{
              style: {
                width: anchorEl_month ? anchorEl_month.clientWidth : undefined
              }
            }}
            MenuListProps={{
              "aria-labelledby": "month"
            }}
          >
            {months.map((m, index) => (
              <MenuItem
                key={index}
                style={{
                  fontFamily: "Poppins",
                  color: payload.month.includes(m) ? "#ec4899" : "black"
                }}
                onClick={(e) => {
                  toggleSelection("month", m);
                  setAnchorEl_month(null);
                }}
              >
                {m}
              </MenuItem>
            ))}
          </Menu>
          <Divider
            orientation="horizontal"
            flexItem
            style={{ marginTop: "0px", marginBottom: "0px" }}
          />
          <ListButton
            id="gen"
            aria-haspopup="true"
            onClick={(e) => setAnchorEl_gen(e.currentTarget)}
          >
            <div className="flex items-center justify-between ">
              <span
                style={{
                  color: payload.genre.length > 0 ? "#ec4899" : "white"
                }}
                className="normal-case"
              >
                {payload.genre && payload.genre.length === 0
                  ? "Genre"
                  : "Genre"}
              </span>
              <KeyboardArrowDownIcon />
            </div>
          </ListButton>
          <Menu
            anchorEl={anchorEl_gen}
            open={open_gen}
            onClose={() => setAnchorEl_gen(null)}
            PaperProps={{
              style: {
                width: anchorEl_gen ? anchorEl_gen.clientWidth : undefined
              }
            }}
            MenuListProps={{
              "aria-labelledby": "gen"
            }}
          >
            {Genre.map((g, index) => (
              <MenuItem
                key={index}
                style={{
                  fontFamily: "Poppins",
                  color: payload.genre.includes(g) ? "#ec4899" : "black"
                }}
                onClick={(e) => {
                  toggleSelection("genre", g);
                  setAnchorEl_gen(null);
                }}
              >
                {g}
              </MenuItem>
            ))}
          </Menu>
          <Divider
            orientation="horizontal"
            flexItem
            style={{ marginTop: "0px", marginBottom: "0px" }}
          />
          <ListButton
            id="loc"
            aria-haspopup="true"
            onClick={(e) => setAnchorEl_loc(e.currentTarget)}
          >
            <div className="flex items-center justify-between ">
              <span
                style={{
                  color: payload.location.length > 0 ? "#ec4899" : "white"
                }}
                className="normal-case"
              >
                {payload.location && payload.location.length === 0
                  ? "Locatie"
                  : "Locatie"}
              </span>
              <KeyboardArrowDownIcon />
            </div>
          </ListButton>
          <Menu
            anchorEl={anchorEl_loc}
            open={open_loc}
            onClose={() => setAnchorEl_loc(null)}
            PaperProps={{
              style: {
                width: anchorEl_loc ? anchorEl_loc.clientWidth : undefined
              }
            }}
            MenuListProps={{
              "aria-labelledby": "loc"
            }}
          >
            {Location.map((l, index) => (
              <MenuItem
                key={index}
                style={{
                  fontFamily: "Poppins",
                  color: payload.location.includes(l) ? "#ec4899" : "black"
                }}
                onClick={(e) => {
                  toggleSelection("location", l);
                  setAnchorEl_loc(null);
                }}
              >
                {l}
              </MenuItem>
            ))}
          </Menu>
          <Divider
            orientation="horizontal"
            flexItem
            style={{ marginTop: "0px", marginBottom: "0px" }}
          />
          <ListButton
            id="festival"
            aria-haspopup="true"
            onClick={(e) => setAnchorEl_festival(e.currentTarget)}
          >
            <div className="flex items-center justify-between ">
              <span
                style={{
                  color: payload.festival.length > 0 ? "#ec4899" : "white"
                }}
                className="normal-case"
              >
                {payload.festival && payload.festival.length === 0
                  ? "Festival"
                  : "Festival"}
              </span>
              <KeyboardArrowDownIcon />
            </div>
          </ListButton>
          <Menu
            anchorEl={anchorEl_festival}
            open={open_festival}
            onClose={() => setAnchorEl_festival(null)}
            PaperProps={{
              style: {
                width: anchorEl_festival
                  ? anchorEl_festival.clientWidth
                  : undefined
              }
            }}
            MenuListProps={{
              "aria-labelledby": "festival"
            }}
          >
            {festivals.map((p, index) => (
              <MenuItem
                key={index}
                style={{
                  fontFamily: "Poppins",
                  color: payload.festival.includes(p) ? "#ec4899" : "black"
                }}
                onClick={(e) => {
                  toggleSelection("festival", p);
                  setAnchorEl_festival(null);
                }}
              >
                {p}
              </MenuItem>
            ))}
          </Menu>
          <Divider
            orientation="horizontal"
            flexItem
            style={{ marginTop: "0px", marginBottom: "0px" }}
          />

          <ListButton
            id="price"
            aria-haspopup="true"
            onClick={(e) => setAnchorEl_price(e.currentTarget)}
          >
            <div className="flex items-center justify-between ">
              <span
                style={{
                  color: payload.price.length > 0 ? "#ec4899" : "white"
                }}
                className="normal-case"
              >
                {payload.price && payload.price.length === 0
                  ? "Prijs"
                  : "Prijs"}
              </span>
              <KeyboardArrowDownIcon />
            </div>
          </ListButton>
          <Menu
            anchorEl={anchorEl_price}
            open={open_price}
            onClose={() => setAnchorEl_price(null)}
            PaperProps={{
              style: {
                width: anchorEl_price ? anchorEl_price.clientWidth : undefined
              }
            }}
            MenuListProps={{
              "aria-labelledby": "price"
            }}
          >
            {Price.map((p, index) => (
              <MenuItem
                key={index}
                style={{
                  fontFamily: "Poppins",
                  color: payload.price.includes(p) ? "#ec4899" : "black"
                }}
                onClick={(e) => {
                  toggleSelection("price", p);
                  setAnchorEl_price(null);
                }}
              >
                {p}
              </MenuItem>
            ))}
          </Menu>
        </div>

        <FilterButton onClick={handleFilterButtonClick}>
          <KeyboardArrowRightIcon className="text-[40px]" />
        </FilterButton>
      </div>
      <div
        className={`relative z-10 mt-15 lg:hidden flex justify-start items-center rounded-[15px] bg-white w-[90%] p-4 gap-2 ${
          FestivalsObject.popUp && "blur-2xl"
        }`}
        onClick={() => setMobFilter(true)}
      >
        <IoFunnelOutline className="text-gray-500 text-lg" />
        <p className="font-Poppins text-gray-500 text-sm font-medium">
          Filter op maand, genre en locatie
        </p>
      </div>
      <div className="relative z-10 mt-15 hidden lg:flex justify-center items-center rounded-[15px] px-2 bg-white h-[60px] w-[50%] min-w-fit">
        <ListButton
          id="month"
          aria-haspopup="true"
          onClick={(e) => setAnchorEl_month(e.currentTarget)}
        >
          <div className="flex items-center justify-between ">
            <span
              style={{ color: payload.month.length > 0 ? "#ec4899" : "black" }}
              className="text-[16px] font-Poppins normal-case"
            >
              {payload.month
                ? payload.month.length === 0
                  ? "Maand"
                  : payload.month.length === 1
                  ? payload.month[0]
                  : "Maand"
                : "Maand"}
            </span>
            <KeyboardArrowDownIcon className="bg-white rounded-[50px]" />
          </div>
        </ListButton>
        <Menu
          anchorEl={anchorEl_month}
          open={open_month}
          onClose={() => setAnchorEl_month(null)}
          PaperProps={{
            style: {
              width: anchorEl_month ? anchorEl_month.clientWidth : undefined
            }
          }}
          MenuListProps={{
            "aria-labelledby": "month"
          }}
        >
          {months.map((m, index) => (
            <MenuItem
              key={index}
              style={{
                fontFamily: "Poppins",
                color: payload.month.includes(m) ? "#ec4899" : "black",
                display: "flex",
                justifyContent: "space-between"
              }}
              onClick={(e) => {
                toggleSelection("month", m);
                setAnchorEl_month(null);
              }}
            >
              {m} {payload.month.includes(m) && <FaCheck />}
            </MenuItem>
          ))}
        </Menu>
        <Divider orientation="vertical" variant="middle" flexItem />
        <ListButton
          id="gen"
          aria-haspopup="true"
          onClick={(e) => setAnchorEl_gen(e.currentTarget)}
        >
          <div className="flex items-center justify-between ">
            <span
              style={{ color: payload.genre.length > 0 ? "#ec4899" : "black" }}
              className="text-[16px] font-Poppins normal-case"
            >
              {payload.genre
                ? payload.genre.length === 0
                  ? "Genre"
                  : payload.genre.length === 1
                  ? payload.genre[0]
                  : "Genre"
                : "Genre"}
            </span>
            <KeyboardArrowDownIcon className="bg-white rounded-[50px]" />
          </div>
        </ListButton>
        <Menu
          anchorEl={anchorEl_gen}
          open={open_gen}
          onClose={() => setAnchorEl_gen(null)}
          PaperProps={{
            style: {
              width: anchorEl_gen ? anchorEl_gen.clientWidth : undefined
            }
          }}
          MenuListProps={{
            "aria-labelledby": "gen"
          }}
        >
          {Genre.map((g, index) => (
            <MenuItem
              key={index}
              style={{
                fontFamily: "Poppins",
                color: payload.genre.includes(g) ? "#ec4899" : "black",
                display: "flex",
                justifyContent: "space-between"
              }}
              className="text-[16px]"
              onClick={(e) => {
                toggleSelection("genre", g);
                setAnchorEl_gen(null);
              }}
            >
              {g} {payload.genre.includes(g) && <FaCheck />}
            </MenuItem>
          ))}
        </Menu>
        <Divider orientation="vertical" variant="middle" flexItem />
        <ListButton
          id="loc"
          aria-haspopup="true"
          onClick={(e) => setAnchorEl_loc(e.currentTarget)}
        >
          <div className="flex items-center justify-between ">
            <span
              style={{
                color: payload.location.length > 0 ? "#ec4899" : "black"
              }}
              className="text-[16px] font-Poppins normal-case"
            >
              {payload.location
                ? payload.location.length === 0
                  ? "Locatie"
                  : payload.location.length === 1
                  ? payload.location[0]
                  : "Locatie"
                : "Locatie"}
            </span>
            <KeyboardArrowDownIcon className="bg-white rounded-[50px]" />
          </div>
        </ListButton>
        <Menu
          anchorEl={anchorEl_loc}
          open={open_loc}
          onClose={() => setAnchorEl_loc(null)}
          PaperProps={{
            style: {
              width: anchorEl_loc ? anchorEl_loc.clientWidth : undefined
            }
          }}
          MenuListProps={{
            "aria-labelledby": "loc"
          }}
        >
          {Location.map((l, index) => (
            <MenuItem
              key={index}
              style={{
                fontFamily: "Poppins",
                color: payload.location.includes(l) ? "#ec4899" : "black",
                display: "flex",
                justifyContent: "space-between"
              }}
              onClick={(e) => {
                toggleSelection("location", l);
                setAnchorEl_loc(null);
              }}
            >
              {l} {payload.location.includes(l) && <FaCheck />}
            </MenuItem>
          ))}
        </Menu>

        <Divider orientation="vertical" variant="middle" flexItem />
        <ListButton
          id="festival"
          aria-haspopup="true"
          onClick={(e) => setAnchorEl_festival(e.currentTarget)}
        >
          <div className="flex items-center justify-between ">
            <span
              style={{
                color: payload.festival.length > 0 ? "#ec4899" : "black"
              }}
              className="text-[16px] font-Poppins normal-case"
            >
              {payload.festival
                ? payload.festival.length === 0
                  ? "Festival"
                  : payload.festival.length === 1
                  ? payload.festival[0]
                  : "Festival"
                : "Festival"}
            </span>
            <KeyboardArrowDownIcon className="bg-white rounded-[50px]" />
          </div>
        </ListButton>
        <Menu
          anchorEl={anchorEl_festival}
          open={open_festival}
          onClose={() => setAnchorEl_festival(null)}
          PaperProps={{
            style: {
              width: anchorEl_festival
                ? anchorEl_festival.clientWidth
                : undefined
            }
          }}
          MenuListProps={{
            "aria-labelledby": "festival"
          }}
        >
          {festivals.map((p, index) => (
            <MenuItem
              key={index}
              style={{
                fontFamily: "Poppins",
                color: payload.festival.includes(p) ? "#ec4899" : "black",
                display: "flex",
                justifyContent: "space-between"
              }}
              onClick={(e) => {
                toggleSelection("festival", p);
                setAnchorEl_festival(null);
              }}
            >
              {p} {payload.festival.includes(p) && <FaCheck />}
            </MenuItem>
          ))}
        </Menu>

        <Divider orientation="vertical" variant="middle" flexItem />
        <ListButton
          id="price"
          aria-haspopup="true"
          onClick={(e) => setAnchorEl_price(e.currentTarget)}
        >
          <div className="flex items-center justify-between ">
            <span
              style={{ color: payload.price.length > 0 ? "#ec4899" : "black" }}
              className="text-[16px] font-Poppins normal-case"
            >
              {payload.price
                ? payload.price.length === 0
                  ? "Prijs"
                  : payload.price.length === 1
                  ? payload.price[0]
                  : "Prijs"
                : "Prijs"}
            </span>
            <KeyboardArrowDownIcon className="bg-white rounded-[50px]" />
          </div>
        </ListButton>
        <Menu
          anchorEl={anchorEl_price}
          open={open_price}
          onClose={() => setAnchorEl_price(null)}
          PaperProps={{
            style: {
              width: anchorEl_price ? anchorEl_price.clientWidth : undefined
            }
          }}
          MenuListProps={{
            "aria-labelledby": "price"
          }}
        >
          {Price.map((p, index) => (
            <MenuItem
              key={index}
              style={{
                fontFamily: "Poppins",
                color: payload.price.includes(p) ? "#ec4899" : "black",
                display: "flex",
                justifyContent: "space-between"
              }}
              onClick={(e) => {
                toggleSelection("price", p);
                setAnchorEl_price(null);
              }}
            >
              {p} {payload.price.includes(p) && <FaCheck />}
            </MenuItem>
          ))}
        </Menu>
        <div
          className="relative overflow-hidden z-50 hidden md:inline-block px-4 py-2 rounded-md text-white uppercase text-base font-bold cursor-pointer transition-all duration-300 bg-[#1474f4] group"
          id="filterButton"
          onClick={handleFilterButtonClick}
        >
          <button
            style={{ position: "relative", zIndex: "50" }}
            className="flex items-center justify-center gap-2 w-full h-full"
          >
            Zoek <KeyboardArrowRightIcon className="text-[40px]" />
          </button>
          <span className="absolute bottom-0 left-0 w-full h-0 group-hover:h-full bg-[#ec4899] rounded-md z-0 transition-all duration-300"></span>
        </div>
      </div>
    </>
  );
};

export default HomeForm;
