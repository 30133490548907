/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import HomeLayout from "../../layouts/HomeLayout";
import { GetAllTickets } from "../../Client/request";
import festImg from "../../assets/festival.png";
import { bg2, h2_style } from "../../styles/home";
import { Divider } from "@mui/material";
import BG2 from "../../assets/BG2.png";
import TicketCard from "../../Components/TicketCard";

import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

// import win from "../../assets/win1.jpg";

const bg3 = {
  background: `url(${BG2}) #B3DDFF 0px -2449.165px / 100% 269.003% no-repeat`,
};

const WinTickets = () => {
  const [tickets, setTickets] = useState([]);
  const [displayTickets, setDisplayTickets] = useState({});
  const [pageLoading, setPageLoading] = useState(true);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [currentMonthOnly, setCurrentMonthOnly] = useState(false);
  const [nextMonthOnly, setNextMonthOnly] = useState(false);
  const [currentWeekOnly, setCurrentWeekOnly] = useState(false);
  const [sortedMonths, setSortedMonths] = useState({});
  const sortFestivals = (festivals) => {
    festivals.forEach((festival) => {
      const duration = new Date(festival.EndDate) - new Date(festival.FromDate);
      const durationInHrs = duration / (1000 * 60 * 60);
      festival.duration = durationInHrs;
      festival.FromDate = new Date(festival.FromDate);
    });

    // Get the current month and year
    const currentDate = new Date();
    const currentMonth = currentDate.toLocaleString("nl-NL", {
      month: "long",
      locale: "nl-NL",
    }); // Current month in Dutch
    const currentYear = currentDate.getFullYear();

    const monthNamesInDutch = [
      "januari",
      "februari",
      "maart",
      "april",
      "mei",
      "juni",
      "juli",
      "augustus",
      "september",
      "oktober",
      "november",
      "december",
    ];
    const currentMonthIndex = monthNamesInDutch.indexOf(
      currentMonth.toLowerCase()
    ); // Index starts from 0

    // Filter out festivals starting from the current month and later
    const filteredFestivals = festivals.filter((festival) => {
      const festivalMonthInDutch = festival.Month.toLowerCase(); // Convert festival month to lowercase Dutch name
      const festivalMonthIndex =
        monthNamesInDutch.indexOf(festivalMonthInDutch); // Index starts from 0
      const festivalYear = festival.FromDate.getFullYear();

      // Compare festival year and month with current year and month
      return (
        festivalYear > currentYear ||
        (festivalYear === currentYear &&
          festivalMonthIndex >= currentMonthIndex)
      );
    });

    if (currentMonthOnly) {
      // Filter out festivals starting from the current month and later
      const filteredFestivals = festivals.filter((festival) => {
        const festivalMonthInDutch = festival.Month.toLowerCase(); // Convert festival month to lowercase Dutch name
        const festivalMonthIndex =
          monthNamesInDutch.indexOf(festivalMonthInDutch); // Index starts from 0
        const festivalYear = festival.FromDate.getFullYear();

        // Compare festival year and month with current year and month
        return (
          festivalYear === currentYear &&
          festivalMonthIndex === currentMonthIndex
        );
      });

      // Sort the filtered festivals
      filteredFestivals.sort((a, b) => {
        return a.FromDate - b.FromDate;
      });

      return filteredFestivals.filter((festival) => {
        return festival.FromDate >= currentDate;
      });
    }

    if (nextMonthOnly) {
      // Filter out festivals starting from the next month
      const filteredFestivals = festivals.filter((festival) => {
        const festivalMonthInDutch = festival.Month.toLowerCase(); // Convert festival month to lowercase Dutch name
        const festivalMonthIndex =
          monthNamesInDutch.indexOf(festivalMonthInDutch); // Index starts from 0
        const festivalYear = festival.FromDate.getFullYear();

        // Compare festival year and month with current year and month
        return (
          festivalYear === currentYear &&
          festivalMonthIndex === currentMonthIndex + 1
        );
      });

      // Sort the filtered festivals
      filteredFestivals.sort((a, b) => {
        return a.FromDate - b.FromDate;
      });
      return filteredFestivals;
    }

    if (currentWeekOnly) {
      // Filter out festivals starting from the current month and later
      const filteredFestivals = festivals.filter((festival) => {
        const festivalMonthInDutch = festival.Month.toLowerCase(); // Convert festival month to lowercase Dutch name
        const festivalMonthIndex =
          monthNamesInDutch.indexOf(festivalMonthInDutch); // Index starts from 0
        const festivalYear = festival.FromDate.getFullYear();

        // Compare festival year and month with current year and month
        return (
          festivalYear === currentYear &&
          festivalMonthIndex === currentMonthIndex &&
          festival.FromDate >= currentDate
        );
      });

      // Sort the filtered festivals
      filteredFestivals.sort((a, b) => {
        return a.FromDate - b.FromDate;
      });

      return filteredFestivals.filter((festival) => {
        return festival.FromDate >= currentDate;
      });
    }

    // Sort the filtered festivals
    filteredFestivals.sort((a, b) => {
      const monthA = monthNamesInDutch.indexOf(a.Month.toLowerCase());
      const monthB = monthNamesInDutch.indexOf(b.Month.toLowerCase());

      // Compare months
      if (monthA === monthB) {
        // If months are the same, compare dates
        return a.FromDate - b.FromDate;
      } else {
        // Otherwise, compare months
        return monthA - monthB;
      }
    });

    const sortedFestivals = filteredFestivals.filter((festival) => {
      return festival.FromDate >= currentDate;
    });

    return sortedFestivals;
  };

  const fetchTickets = async () => {
    const response = await GetAllTickets();
    if (response) {
      setLoading(false);
      const available = response;
      // console.log("available", available);
      const sortedFestival = {};

      sortFestivals(available)?.forEach((item) => {
        if (!item.IsPromoted) {
          const month = item.Month;
          const duration = new Date(item.EndDate) - new Date(item.FromDate);
          const durationInHrs = duration / (1000 * 60 * 60);
          item.duration = durationInHrs;
          if (!sortedFestival[month]) {
            sortedFestival[month] = [];
          }
          sortedFestival[month].push(item);
        }
      });

      const sortedFestivalObjects = Object.fromEntries(
        Object.entries(sortedFestival).map(([key, value]) => [key, value])
      );

      setSortedMonths(sortedFestivalObjects);
      // console.log("SORTED", sortedFestivalObjects);
      setTickets(available);
      const sortedTicket = {};
      available?.forEach((item) => {
        const month = item.Month;
        if (!sortedTicket[month]) {
          sortedTicket[month] = [];
        }
        sortedTicket[month].push(item);
      });

      const sortedTickets = Object.fromEntries(
        Object.entries(sortedTicket).map(([key, value]) => [key, value])
      );

      setDisplayTickets(sortedFestivalObjects);

      // console.log(sortedTickets);
    }
  };

  useEffect(() => {
    setLoading(true);
    fetchTickets();
  }, []);

  useEffect(() => {
    // after 2 seconds set pageLoading to false
    setTimeout(() => {
      setPageLoading(false);
    }, 400);
  }, []);

  return (
    <>
      {pageLoading ? (
        <>
          {/* loader */}
          <div className="flex items-center justify-center h-screen w-screen bg-[#5ACCFF]">
            <div className="flex items-center justify-center flex-col">
              <h1 className="text-white font-Bowlby text-2xl md:text-5xl">
                Win Tickets
              </h1>
              <div className="flex items-center justify-center">
                <div className="animate-spin rounded-full h-20 md:h-32 w-20 md:w-32 border-t-2 border-[#fff]"></div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <HomeLayout>
          {/* <div
        className="w-full bg-cover bg-center bg-no-repeat h-[100vh] md:p-20 p-4 flex flex-col items-start justify-center"
        style={{
          backgroundImage: `url(${win})`
        }}
      >
        <div className="flex flex-col items-start justify-start  gap-8 md:w-[50%] w-full text-white">
          <p className="font-Bowlby md:text-6xl text-3xl font-bold text-white">
            Begin je week weer zorgeloos
          </p>
          <p className="font-Poppins md:text-4xl text-2xl font-medium text-white">
            Join de 6.211 festivalgangers die gebruik maken van SKIP JE DIP!
          </p>
          <div className="flex md:items-center items-start justify-start w-full">
            <div className="relative overflow-hidden z-30 hidden md:inline-block px-4 py-2 rounded-md text-white uppercase md:text-4xl text-base font-bold cursor-pointer transition-all duration-300 bg-[#1474f4] group">
              <button className="z-30 relative">Ontdek nu!</button>
              <span className="absolute bottom-0 left-0 w-full h-0 group-hover:h-full bg-[#ec4899] rounded-md z-0 transition-all duration-300"></span>
            </div>
          </div>
        </div>
      </div> */}
          {/* <div className="shadow-xl bg-[#1474f4] py-1 flex flex-row items-center justify-center w-full sticky xl:top-[108px] md:top-[154px] top-[96px] z-50">
        <p className="text-white md:text-base text-xs font-Poppins font-medium">
          GRATIS beFesti. waaier bij 5 zakjes
        </p>
      </div> */}
          <div className="relative top-0 md:mt-40 mt-28 mb-6 w-full">
            <h1 className="text-5xl md:text-[90px] font-Bowlby leading-[52px] md:leading-[99px] text-center text-white relative z-10">
              Win Tickets
            </h1>
          </div>
          <div className="flex items-center justify-center flex-col gap-4 md:w-full z-50">
            <div className="flex items-center mt-[50px] flex-col w-full">
              <div
                style={{ ...bg3 }}
                className="w-[90%] p-[20px] rounded-[20px] z-10 relative"
              >
                {!loading && tickets.length > 0 ? (
                  Object.keys(displayTickets).map((month, index) => {
                    return (
                      <div key={index}>
                        <div className="w-full flex flex-row items-center justify-center gap-2">
                          <h1 className="text-white font-Bowlby text-[20px] md:text-[32px] md:leading-[52px] w-fit">
                            {month}
                          </h1>

                          <Divider
                            style={{
                              flexGrow: 1, // This makes the Divider take up the remaining space
                              margin: "0 10px",
                              borderColor: "white",
                              height: "5px",
                              alignSelf: "center",
                            }}
                          />
                        </div>
                        {displayTickets[month].map((ticket, index) => {
                          return (
                            <TicketCard
                              f={ticket}
                              key={index}
                              line={
                                index === displayTickets[month].length - 1
                                  ? false
                                  : true
                              }
                            />
                          );
                        })}
                      </div>
                    );
                  })
                ) : (
                  <div className="flex items-center justify-center w-full">
                    <h1 className="text-white font-Bowlby text-base md:text-[32px] md:leading-[52px]">
                      Geen festivals gevonden
                    </h1>
                  </div>
                )}
                {loading && (
                  <div className="flex flex-col gap-4">
                    <Skeleton height={50} style={{ borderRadius: "50px" }} />
                    <Skeleton height={50} style={{ borderRadius: "50px" }} />
                    <Skeleton height={50} style={{ borderRadius: "50px" }} />
                  </div>
                )}
              </div>
            </div>
            <div
              className="relative overflow-hidden z-40  px-4 py-2 rounded-md text-white uppercase text-base font-bold cursor-pointer transition-all duration-300 bg-[#ec4899] group"
              onClick={() => navigate("/")}
            >
              <button className="z-50 relative font-Poppins">
                Check de hele Festival Agenda
              </button>
              <span className="absolute bottom-0 left-0 w-full h-0 group-hover:h-full bg-[#1474f4] rounded-md z-0 transition-all duration-300"></span>
            </div>
          </div>
        </HomeLayout>
      )}
    </>
  );
};

export default WinTickets;
